import React from 'react';
import { render } from 'react-dom';
import moment from 'moment';
import consumer from '../../../channels/consumer';
import '../../flash';

class LearnNotificationNav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      notifications: [],
      notificationsCount: 0,
    };
  }

  componentDidMount() {
    const _this = this;
    $.ajax({
      url: this.props.url,
      type: 'get',
      data: {
        status: 'unread',
        format: 'json',
      },
    }).done((response) => {
      const notifications = response.data;
      const notificationsCount = response.meta.totalItems;
      _this.setState(() => ({
        notifications,
        notificationsCount,
      }));
    });
    if (!App.notifications) { this.setupSubscription(); }
  }

  setupSubscription() {
    const _this = this;
    consumer.subscriptions.create('NotificationsChannel', {
      received(notifData) {
        const notification = notifData.data;
        _this.setState(() => ({
          notifications: [notification].concat(_this.state.notifications),
        }));
        _this.flashNotification(notification);
      },
    });
  }

  flashNotification(notification) {
    const message = { type: 'notice', text: notification.attributes.text };
    Utilities.FMessages.push(message);
    setTimeout(() => {
      Utilities.FMessages.splice(message, 1);
      window.flashDiv.messages(Utilities.FMessages);
    }, 4000);
    window.flashDiv.messages(Utilities.FMessages);
  }

  render() {
    const notificationsLink = [];
    $.each(this.state.notifications.slice(0, 5), (i, notif) => {
      notificationsLink.push(<NotificationLink notification={notif} key={i} />);
    });
    let notificationCounter;

    if (this.state.notificationsCount > 0) {
      notificationCounter = <div className="notification-counter">{this.state.notificationsCount}</div>;
    }
    return (
      <div>
        <div className="dropdown">
          <a href="#" id="dropdownMenuButton3" data-toggle="dropdown" aria-expanded="false" aria-label="Notification">
            <svg className="icon icon-lg icon"><use xlinkHref={`${window.SpritePath}#notifications-active`} /></svg>
          </a>
          <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton3">
            {notificationsLink}
            <a className="dropdown-item text-center text-secondary" href={this.props.notificationsUrl}>View all</a>
          </div>
        </div>
        {notificationCounter}
      </div>
    );
  }
}

class NotificationLink extends React.Component {
  render() {
    const { notification } = this.props;
    const shortTitle = $.trim(notification.attributes.title);
    return (
      <div>
        <a className="dropdown-item" href={notification.attributes.path}>
          {shortTitle}
          <br />
          <small>
            {moment(notification.attributes.createdAt).fromNow()}
          </small>
        </a>
        <hr />
      </div>

    );
  }
}

$(document).on('turbo:load', () => {
  const $el = $('#learn-navigation-notifications');
  if ($el.length) {
    const url = $el.data('url');
    const notificationsUrl = $el.data('notifications-url');
    render(<LearnNotificationNav url={url} notificationsUrl={notificationsUrl} />, $el[0]);
  }
});

export default LearnNotificationNav;
