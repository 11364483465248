import React from 'react';
import LatexResistentMarkdownConverter from '../utilities/latex-resistent-markdown-converter.js';

export default class HintsMessageComponent extends React.Component {
  componentDidMount() {
    MathJax.Hub.Queue(['Typeset', MathJax.Hub]);
  }

  componentDidUpdate() {
    MathJax.Hub.Queue(['Typeset', MathJax.Hub]);
  }

  render() {
    const { hints } = this.props;
    let className = 'card mb-3';
    let hintElement;
    const hintElements = [];
    if (this.props.success) {
      className += ' text-white bg-success';
    } else {
      className += ' text-white bg-danger';
    }
    hints.forEach((hint, index) => {
      const htmlHint = LatexResistentMarkdownConverter.convert(hint);
      hintElements.push(<p key={index} className="card-text" dangerouslySetInnerHTML={{ __html: htmlHint }} />);
    });
    if (hints.length) {
      hintElement = <div className="card-body">{hintElements}</div>;
    }
    return (
      <div className={className}>
        <div className="card-header">{this.props.message}</div>
        {hintElement}
      </div>
    );
  }
}
