import React from 'react';
import PropTypes from 'prop-types';
import autosize from 'autosize/dist/autosize.js';

class LearnQuizzTextComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      answer: props.element.attributes.subscriptionLastAnswer || '',
      message: null,
      errors: null,
      success: !!props.element.attributes.subscriptionLastAnswer,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdateAnswer = this.handleUpdateAnswer.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
  }

  componentDidMount() {
    autosize(document.querySelectorAll('textarea'));
  }

  componentDidUpdate() {
    MathJax.Hub.Queue(['Typeset', MathJax.Hub]);
    autosize(document.querySelectorAll('textarea'));
  }

  handleChange(event) {
    this.setState({
      answer: event.target.value,
    });
  }

  handleUpdateAnswer() {
    this.setState({
      success: false,
    });
  }

  handleSuccess(truthy) {
    this.props.handleSuccess(this.props.element.id, truthy);
  }

  handleSubmit(event) {
    event.preventDefault();
    const _this = this;
    const element = (typeof (this.props.element) === 'object' ? this.props.element : JSON.parse(this.props.element).data);
    const url = element.links.textQuizzAnswer;
    const { answer } = this.state;
    $.ajax({
      beforeSend(xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')); },
      url,
      type: 'post',
      data: {
        text_quizz_answer: {
          content: answer,
        },
      },
    }).done(() => {
      _this.handleSuccess(true);
      _this.setState({
        errors: null,
        message: element.attributes.successMessageHtml,
        success: true,
      });
    }).fail((jqXHR, textStatus, errorThrown) => {
      _this.handleSuccess(false);
      _this.setState({
        errors: jqXHR.responseJSON ? jqXHR.responseJSON.errors : { Error: [errorThrown] },
        success: false,
      });
    });
  }

  render() {
    const { element } = this.props;

    const answerInputName = 'text_quizz_answer[content]';
    const answerInputId = 'text_quizz_answer_content';
    const answerFormGroupClass = 'form-group';
    const answerInputClass = 'text_quizz_answer_content form-control';

    const errors = [];
    let message;

    if (this.state.message) {
      message = (
        <div className="card mb-3 text-white bg-success">
          <div className="card-header" dangerouslySetInnerHTML={{ __html: this.state.message }} />
        </div>
      );
    }

    if (this.state.errors) {
      $.each(this.state.errors, (attr, errs) => {
        let attribute = attr;
        if (attr === 'content') { attribute = 'Answer'; }
        $.each(errs, (i, err) => {
          errors.push(
            <div key={i} className="alert alert-danger">
              <div className="help-block">
                {attribute} {err}
              </div>
            </div>,
          );
        });
      });
    }

    let content;
    if (this.state.success) {
      content = (
        <div>
          <div dangerouslySetInnerHTML={{ __html: element.attributes.descriptionHtml }} />
          {message}
          <h2>Your answer</h2>
          <div>
            <p>{this.state.answer}</p>
          </div>
          <div className="form-group">
            <button className="btn btn-info" onClick={this.handleUpdateAnswer}>
              Update answer
            </button>
          </div>
        </div>
      );
    } else {
      content = (
        <form className="quizz" onSubmit={this.handleSubmit}>
          <div dangerouslySetInnerHTML={{ __html: element.attributes.descriptionHtml }} />
          {errors}
          <div className={answerFormGroupClass}>
            <textarea onChange={this.handleChange} id={answerInputId} name={answerInputName} className={answerInputClass} value={this.state.answer} />
          </div>
          <div className="form-group">
            <button type="submit" className="btn btn-info">
              Submit answer
            </button>
          </div>
        </form>
      );
    }

    return (content);
  }
}

LearnQuizzTextComponent.propTypes = {
  element: PropTypes.object,
};

export default LearnQuizzTextComponent;
