import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

class QuizzRadioComponent extends React.Component {
  render() {
    const { answer } = this.props;
    const answerId = answer.id || (new Date().getTime()) + _.random(10000);
    let subText = null;
    if (this.props.hint) {
      subText = <small className="form-text text-muted text-danger" dangerouslySetInnerHTML={{ __html: ` ${this.props.hint}` }} />;
    } else if (this.props.answerMessage) {
      subText = (
        <div className="card mt-0 mb-3 text-white bg-success" style={{ opacity: '0.8' }}>
          <div className="card-body p-2">{this.props.answerMessage}</div>
        </div>
      );
    }

    return (
      <div className="form-check">
        <input type="radio" id={`input-answer-${answerId}`} className="form-check-input" name="answer" value={answerId} onClick={this.props.onClick} defaultChecked={this.props.checked} />
        <label className="form-check-label" htmlFor={`input-answer-${answerId}`}>
          <div dangerouslySetInnerHTML={{ __html: ` ${answer.textHtml}` }} />
        </label>
        {subText}
      </div>
    );
  }
}

QuizzRadioComponent.propTypes = {
  answer: PropTypes.object,
  hint: PropTypes.string,
  answerMessage: PropTypes.string,
  checked: PropTypes.bool,
};

QuizzRadioComponent.defaultProps = {
  checked: false,
};

export default QuizzRadioComponent;
