import React from 'react';
import { findDOMNode } from 'react-dom';
import autosize from 'autosize/dist/autosize.js';

class LearnFeedbackComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: null,
      success: false,
      errors: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const node = findDOMNode(this);
    autosize(node.querySelectorAll('textarea'));
  }

  handleChange(event) {
    const input = event.target;
    const text = input.value;
    this.setState({ text });
  }

  handleSubmit(event) {
    event.preventDefault();
    const _this = this;
    const element = (typeof (this.props.element) === 'object' ? this.props.element : JSON.parse(this.props.element).data);
    const url = element.links.feedbackAnswers;
    $.ajax({
      beforeSend(xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')); },
      url,
      type: 'post',
      data: {
        feedback_answer: { text: this.state.text },
      },
    }).done(() => {
      _this.setState(() => ({
        errors: null,
        success: true,
      }));
    }).fail((data) => {
      const { errors } = data.responseJSON;
      _this.setState(() => ({
        errors,
        success: false,
      }));
    });
  }

  render() {
    const { element } = this.props;

    const errors = this.state.errors || {};
    const baseErrors = [];

    if (errors.base) {
      $.each(errors.base, (i, error) => {
        baseErrors.push(<div key={i} className="alert alert-danger mb-2">{error}</div>);
      });
    }

    const textInputName = 'feedback_answer[text]';
    const textInputErrors = [];
    if (errors.text) {
      $.each(errors.text, (i, error) => {
        textInputErrors.push(
          <div key={i} className="form-control-feedback has-danger">
            Text {error}
          </div>,
        );
      });
    }

    let form = (
      <div>
        <div dangerouslySetInnerHTML={{ __html: element.attributes.descriptionHtml }} />
        <div className="card">
          <div className="card-body">
            <form onSubmit={this.handleSubmit} onChange={this.handleChange} className="form-vertical form-full-width">
              {baseErrors}
              <div className="form-group">
                {textInputErrors}
                <textarea name={textInputName} className="form-control" />
              </div>
              <div className="form-group">
                <button type="submit" className="btn btn-primary">Send feedback</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );

    if (this.state.success) {
      form = (
        <div className="flash-bar success">
          <div className="content">
            <div className="flash-bar__info">
              Thank you for your feedback :-)
            </div>
          </div>
        </div>
      );
    }

    return (form);
  }
}

export default LearnFeedbackComponent;
