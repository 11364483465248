['turbo:load', 'turbo:render'].forEach((evt) => {
  document.addEventListener(evt, () => {
    const checkbox = document.getElementById('billing_addr_cb');
    if (checkbox) {
      const billingAddress = document.getElementById('billing_address');

      checkbox.onchange = function onchange() {
        if (this.checked) {
          billingAddress.style.opacity = 1;
          billingAddress.classList.add('visible');
        } else {
          billingAddress.style.opacity = 0;
          setTimeout(() => {
            billingAddress.classList.remove('visible');
          }, 500);
        }
      };
      // Called after page load so that the checkbox and the component
      // are in synch at start up time.
      checkbox.onchange();
    }
  });
});
