import { legacy_createStore as createStore, applyMiddleware, compose } from 'redux';
import rootReducer from '../../reducers/learn';
import apiMiddleware from '../../middlewares/api';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(initialState) {
  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(
      applyMiddleware(apiMiddleware),
    ),
  );

  return store;
}
