import React from 'react';
import _ from 'lodash';

class TimeTracker extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);

    const { duration = 0, disabled = false } = this.props;
    this.state = { duration, disabled };
  }

  onChange(e) {
    this.setState({ duration: e.target.value });
    this.props.handleChange(e.target.value);
  }

  renderLegend() {
    if (this.props.label) {
      return <legend className="col-sm-4">Time Spent ({this.props.label}):</legend>;
    }

    return <legend className="col-sm-2">Time Spent:</legend>;
  }

  render() {
    const { errorLabel = 'time_tracker', label = '' } = this.props;

    let classes = '';
    let errors = null;
    if (errorLabel) {
      errors = this.props.errors[`${errorLabel}.duration_in_minutes`];
    } else {
      errors = this.props.errors.duration_in_minutes;
    }

    const errorElements = [];
    if (errors && !_.isEmpty(errors)) {
      classes += ' has-danger is-invalid';
      $.each(errors, (i, error) => {
        errorElements.push(
          <p key={i} className="form-control-feedback has-danger is-invalid">
            Time {error}
          </p>,
        );
      });
    }

    return (
      <fieldset className="fieldset-time_tracker">
        <div className="form-row form-row-time_tracker">
          {this.renderLegend()}
          <div className={`form-group col-auto ${classes}`}>
            <input className="form-control time-tracker" type="number" id={`duration${label}`} value={this.state.duration} onChange={this.onChange} disabled={this.state.disabled} />
            <label htmlFor={`duration${label}`}>
              minutes
            </label>
          </div>
        </div>
        {errorElements}
      </fieldset>
    );
  }
}

export default TimeTracker;
