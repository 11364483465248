import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import autosize from 'autosize/dist/autosize.js';
import * as Actions from '../../actions';
import FormFeedbackComponent from './feedback-form-component';
import ElementAdminButtonComponent from '../elements/element-admin-button-component';

class FeedbackElementComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.cancelForm = this.cancelForm.bind(this);
  }

  componentDidMount() {
    autosize(document.querySelectorAll('textarea'));
  }

  componentDidUpdate() {
    MathJax.Hub.Queue(['Typeset', MathJax.Hub]);
    Prism.highlightAll();
  }

  handleChange(event) {
    const input = event.target;
    const text = input.value;
    this.setState({ text });
  }

  handleSubmit(event) {
    event.preventDefault();
    const _this = this;
    const { element } = this.props;
    const url = element.links.feedbackAnswers;
    if (this.props.preview) {
      _this.setState(() => ({
        errors: { base: ['Feedback cannot be submitted in preview mode'] },
        success: false,
      }));
    } else {
      $.ajax({
        beforeSend(xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')); },
        url,
        type: 'post',
        data: {
          feedback_answer: {
            text: this.state.text,
          },
          preview: true,
        },
      }).done(() => {
        _this.setState(() => ({
          errors: null,
          success: true,
        }));
      }).fail((data) => {
        const { errors } = data.responseJSON;
        _this.setState(() => ({
          errors,
          success: false,
        }));
      });
    }
  }

  cancelForm(e) {
    if (e) e.preventDefault();
    this.props.actions.cancelElementEditing();
  }

  render() {
    const { element } = this.props;
    const errors = this.state.errors || {};
    const baseErrors = [];

    if (errors.base) {
      $.each(errors.base, (i, error) => {
        baseErrors.push(<div key={i} className="alert alert-danger mb-2">{error}</div>);
      });
    }

    const textInputName = 'feedback_answer[text]';
    const textInputErrors = [];

    if (errors.text) {
      $.each(errors.text, (i, error) => {
        textInputErrors.push(
          <div key={i} className="form-control-feedback has-danger">
            Text {error}
          </div>,
        );
      });
    }

    let content = (
      <div className="unit-element">
        <ElementAdminButtonComponent editable={this.props.editable} element={element} unitContentMdStatus={this.props.unit.attributes.githubContentMdStatus} />
        <div dangerouslySetInnerHTML={{ __html: element ? element.attributes.descriptionHtml : '' }} />
        <form onSubmit={this.handleSubmit} onChange={this.handleChange} className="form-vertical form-full-width">
          {baseErrors}
          <div className="form-group">
            {textInputErrors}
            <textarea name={textInputName} className="form-control" />
          </div>
          <div className="form-group">
            <button type="submit" onClick={this.handleSubmit} className="btn btn-primary">Send feedback</button>
          </div>
        </form>
      </div>
    );

    if (this.state.success) {
      content = (
        <div className="flash-bar success">
          <div className="content">
            <div className="flash-bar__info">
              Thank you for your feedback :-)
            </div>
          </div>
        </div>
      );
    }

    if (this.props.editingElement && this.props.editingElement === element) {
      content = (
        <FormFeedbackComponent
          element={element}
          unit={this.props.unit}
          cancelForm={this.cancelForm}
          url={element.links.self}
          method="patch"
          submitForm={this.props.submitForm}
          success={Actions.UPDATE_UNIT_AND_ELEMENTS}
          errors={this.props.errors}
        />
      );
    }

    return (
      <div>
        {content}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    unit: state.units.unit,
    editingElement: state.elements.editingElement,
    errors: state.forms.errors,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackElementComponent);
