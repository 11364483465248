import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as Actions from '../../actions';
import FormQuizzSingleChoiceComponent from './form-quizz-single-choice-component';
import HintComponent from '../hint-component';
import QuizzRadioComponent from '../quizz-radio-component';
import ElementAdminButtonComponent from '../elements/element-admin-button-component';

class QuizzSingleChoiceElementComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hints: [], answersSuccessMessages: {} };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.cancelForm = this.cancelForm.bind(this);
  }

  componentDidMount() {
    this.setData(this.props);
  }

  componentDidUpdate() {
    MathJax.Hub.Queue(['Typeset', MathJax.Hub]);
    Prism.highlightAll();
  }

  handleChange(event) {
    this.setState({
      answerId: event.target.value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    const _this = this;
    const { element } = this.props;
    const url = element.links.grader;
    const { answerId } = this.state;
    if (this.props.preview) {
      _this.setState(() => ({
        answerId: null,
        message: 'Quiz cannot be submitted in preview mode',
        hints: [],
        answersSuccessMessages: {},
        success: false,
      }));
    } else {
      $.ajax({
        beforeSend(xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')); },
        url,
        type: 'post',
        data: {
          quizz_single_choice_grader: { answer_id: answerId },
        },
      }).done((response) => {
        _this.setState(() => ({
          answerId,
          message: response.message,
          hints: response.hints,
          answersSuccessMessages: response.answers_success_messages,
          success: true,
        }));
      }).fail((data) => {
        const { hints } = data.responseJSON;
        _this.setState(() => ({
          answerId,
          message: data.responseJSON.message,
          hints,
          answersSuccessMessages: data.responseJSON.answers_success_messages,
          success: false,
        }));
      });
    }
  }

  setData(props) {
    if (props.preview) {
      this.setState({ hints: [], answersSuccessMessages: {} });
    } else {
      const { element } = props;
      if (element) {
        this.setState({
          answerId: element.attributes.subscriptionLastAnswerId,
          message: element.attributes.grading ? element.attributes.grading.message : null,
          hints: element.attributes.grading ? element.attributes.grading.hints : {},
          answersSuccessMessages: element.attributes.grading ? element.attributes.grading.answers_success_messages : {},
          success: element.attributes.grading ? element.attributes.grading.success : false,
        });
      }
    }
  }

  cancelForm(e) {
    if (e) e.preventDefault();
    this.props.actions.cancelElementEditing();
  }

  render() {
    const _this = this;
    const { element } = this.props;
    const answersRadioButtons = [];
    const { hints } = this.state;
    const { answersSuccessMessages } = this.state;
    const answersData = element ? element.attributes.answers : [];
    _.sortBy(answersData, ['position', 'id']).forEach((answer, index) => {
      answersRadioButtons.push(
        <QuizzRadioComponent
          answer={answer}
          key={index}
          hint={hints[answer.id]}
          answerMessage={answersSuccessMessages[answer.id]}
          onClick={_this.handleChange}
        />,
      );
    });

    let hintComponent;
    if (hints) {
      if (this.state.message || hints.general) {
        hintComponent = <HintComponent message={this.state.message} hints={hints.general ? [hints.general] : []} success={this.state.success} />;
      }
    }

    let content;

    if (element) {
      content = (
        <div className="unit-element">
          <ElementAdminButtonComponent editable={this.props.editable} element={element} unitContentMdStatus={this.props.unit.attributes.githubContentMdStatus} />
          <div dangerouslySetInnerHTML={{ __html: element ? element.attributes.descriptionHtml : '' }} />
          {hintComponent}
          <form className="quizz form" onSubmit={this.handleSubmit}>
            {answersRadioButtons}
            <div className="form-group">
              <button type="submit" onClick={this.handleSubmit} className="btn btn-primary">Submit</button>
            </div>
          </form>
        </div>
      );
    }

    if (this.props.editingElement && this.props.editingElement === element) {
      content = (
        <FormQuizzSingleChoiceComponent
          element={element}
          unit={this.props.unit}
          cancelForm={this.cancelForm}
          url={element.links.self}
          method="patch"
          submitForm={this.props.submitForm}
          success={Actions.UPDATE_UNIT_AND_ELEMENTS}
          errors={this.props.errors}
        />
      );
    }

    return (
      <div>
        {content}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    unit: state.units.unit,
    editingElement: state.elements.editingElement,
    errors: state.forms.errors,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuizzSingleChoiceElementComponent);
