import React from 'react';
import { findDOMNode } from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import autosize from 'autosize/dist/autosize.js';
import * as Actions from '../../../actions_learn';
import Flash from '../../../utilities/flash';
import MarkdownPreviewTextareaControlled from '../../markdown-preview-textarea-controlled-component';
import LatexResistentMarkdownConverter from '../../../utilities/latex-resistent-markdown-converter.js';
import TimeTracker from '../time-tracker/time-tracker.jsx';

class LearnCommentFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textMd: props.comment ? props.comment.attributes.textMd : '',
      timeTrackable: !props.isLearner && (props.comment ? props.comment.attributes.timeTrackable : true),
      disabled: false,
      errors: {},
      duration: props.comment ? props.comment.attributes.durationInMinutes : 0,
    };

    this.submitForm = this.submitForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleTimeTrackingChanged = this.handleTimeTrackingChanged.bind(this);
  }

  componentDidMount() {
    const node = findDOMNode(this);
    autosize(node.querySelectorAll('textarea'));
    if (this.props.comment) {
      const textHtml = LatexResistentMarkdownConverter.convert(this.props.comment.attributes.textMd);
      this.setState({
        textHtml,
      });
    }
  }

  handleChange(e) {
    e.preventDefault();
    const { target } = e;
    if (target.classList.contains('comment_text_md')) {
      const textHtml = LatexResistentMarkdownConverter.convert(target.value);
      this.setState({
        disabled: false,
        errors: {},
        textMd: target.value,
        textHtml,
      });
    } else {
      const { name } = target.dataset;
      const { value } = target;
      const data = {
        errors: {},
        disabled: false,
      };
      data[name] = value;
      this.setState(data);
    }
  }

  handleCancel(e) {
    e.preventDefault();
    this.props.actions.editComment(null);
  }

  handleTimeTrackingChanged(duration) {
    this.setState({ duration });
  }

  submitForm(e) {
    e.preventDefault();
    const _this = this;
    this.setState({ disabled: true });

    const _data = {
      comment: {
        text_md: this.state.textMd,
        text_html: this.state.textHtml,
      },
    };
    if (this.state.timeTrackable) {
      _data.time_tracker = {
        duration_in_minutes: this.state.duration,
      };
    }

    $.ajax({
      url: this.props.url,
      method: this.props.comment ? 'PUT' : 'POST',
      data: _data,
    }).done((response, message, xhr) => {
      if (_this.props.comment) {
        _this.props.actions.updateComment(response);
        _this.props.actions.cancelEditComment(response);
        _this.props.actions.setIncludedItems(response);
      } else {
        _this.props.actions.setIncludedUsers(response);
        _this.props.actions.addComment(response);
        _this.props.actions.setIncludedItems(response);
        $('.modal').modal('hide').on('hidden.bs.modal', () => {
          _this.setState({
            errors: {},
            disabled: false,
            textMd: '',
            textHtml: '',
            duration: 0,
          });
        });
      }
      Flash.handleFlashMessagesHeader(window.flashDiv, xhr);
    }).fail((data) => {
      if (data.responseJSON && data.responseJSON.errors) {
        const { errors } = data.responseJSON;
        _this.setState({
          errors,
          disabled: false,
        });
      }
      Flash.handleFlashMessagesHeader(window.flashDiv, data);
    });
  }

  render() {
    const { errors } = this.state;
    const { comment } = this.props;

    const textMdInputName = 'comment[text_md]';
    const textMdInputId = `comment${this.props.commentId || ''}-text`;
    const textMdInputClass = 'comment_text_md form-control';
    const textHtmlInputName = 'comment[text_html]';

    const textField = (
      <MarkdownPreviewTextareaControlled
        label="Comment"
        labelClassName="col-form-label"
        id={textMdInputId}
        className={textMdInputClass}
        mdName={textMdInputName}
        htmlName={textHtmlInputName}
        valueMd={this.state.textMd}
        valueHtml={this.state.textHtml}
        errors={errors.text_md}
        required="false"
        onChange={this.handleChange}
        isLearner={this.props.isLearner}
        calendlyUrl={this.props.calendlyUrl}
      />
    );

    let submitText = 'Post comment';
    let submitingText = 'Posting comment';
    let cancelButton = <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>;

    if (comment) {
      submitText = 'Update comment';
      submitingText = 'Updating comment';
      cancelButton = <button type="button" className="btn btn-secondary" onClick={this.handleCancel}>Cancel</button>;
    }

    let timeTracker;
    if (this.state.timeTrackable) {
      timeTracker = (
        <TimeTracker
          errors={errors}
          duration={this.state.duration}
          handleChange={this.handleTimeTrackingChanged}
        />
      );
    }

    return (
      <form id="learn-comment-form" onSubmit={this.submitForm}>
        <div className="form-group">
          {textField}
        </div>
        {timeTracker}
        <div className="modal-footer">
          <input type="submit" name="commit" value={submitText} className="btn btn-primary" data-disable-with={submitingText} disabled={this.state.disabled} />
          {cancelButton}
        </div>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    forms: state.forms,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LearnCommentFormComponent);
