import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';

import consumer from '../../../channels/consumer';
import * as Actions from '../../../actions_learn';

import HintComponent from '../../hint-component';

class LearnMiniProjectDefinitionElementComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (App.miniProjects) { consumer.subscriptions.remove(App.miniProjects); }
    this.setMiniProjectStates(this.props.element);
    this.setupSubscription();
  }

  setupSubscription() {
    const _this = this;
    App.miniProjects = consumer.subscriptions.create({
      channel: 'ProjectsChannel',
      type: 'mini',
      id: this.props.element.attributes.subscriptionProjectId,
    }, {
      received(miniProjectDefinition) {
        _this.setMiniProjectStates(miniProjectDefinition.data);
      },
    });
  }

  setMiniProjectStates(element) {
    this.setState({
      element,
      workflows: element.attributes.subscriptionWorkflows ? element.attributes.subscriptionWorkflows : [],
    });
    const completions = {
      ...this.props.completions,
      [element.id]: element.attributes.subscriptionWorkflows.length > 0 && element.attributes.subscriptionWorkflows.every((w) => w.state === 'success'),
    };
    this.props.actions.setCompletionState({ completions });
  }

  htmlState(state) {
    let htmlState;

    switch (state) {
      case 'action_required':
      case 'startup_failure':
        htmlState = <span key={state}><i className="fas fa-exclamation-triangle text-danger" /></span>; break;
      case 'cancelled':
      case 'timed_out':
      case 'failure':
        htmlState = <span key={state}><i className="fas fa-circle-xmark text-danger" /></span>; break;
      case 'success':
        htmlState = <span key={state}><i className="fas fa-circle-check text-success" /></span>; break;
      case 'neutral':
      case 'not_yet_started':
        htmlState = <span key={state}><i className="fas fa-square text-secondary" /></span>; break;
      case 'pending':
      default:
        htmlState = <span key={state}><i className="fas fa-solid fa-cog fa-spin text-info" /></span>;
    }

    return htmlState;
  }

  render() {
    const timezone = $("meta[name='timezone']").attr('content');
    const { element } = this.state;
    const workflows = [];

    let hintComponent;
    if (element && element.attributes.subscriptionLastPush) {
      if (element.attributes.subscriptionLastPush.state === 'success') {
        let congratulationsMessage = 'Congratulations! Your project is validated.';
        if (element.attributes.congratulationsMessage) {
          congratulationsMessage = element.attributes.congratulationsMessage;
        }
        hintComponent = <HintComponent message={congratulationsMessage} hints={[]} success={true} />;
      }
    }

    let content;
    if (this.state.workflows && this.state.workflows.length > 0) {
      $.each(this.state.workflows, (i, w) => {
        const state = this.htmlState(w.state);
        workflows.push(<li key={i}>{state} <a href={w.url}>{w.name}</a></li>);
      });

      let repoPath;
      if (element && element.attributes.subscriptionRepositoryPath) {
        const url = `https://github.com/${element.attributes.subscriptionRepositoryPath}`;
        repoPath = (<a href={url}>Your GitHub repository</a>);
      } else {
        repoPath = 'GitHub Repository creation pending...';
      }

      let push;
      let globalState;
      if (element && element.attributes.subscriptionLastPush) {
        if (element.attributes.subscriptionLastPush.state) {
          push = `Last push at ${moment(element.attributes.subscriptionLastPush.stateAt).tz(timezone).format('DD-MM-YYYY HH:mm')}`;
        } else {
          push = 'Nothing has been pushed yet.';
        }
        globalState = (<div className="status">{this.htmlState(element.attributes.subscriptionLastPush.state)}</div>);
      }

      content = (
        <div className="row">
          <div className="col-6">
            <ul style={{ listStyleType: 'none' }}>
              {workflows}
            </ul>
          </div>
          <div className="col-6">
            {repoPath}
            <br />
            {push}
            {globalState}
          </div>
        </div>
      );
    } else {
      content = (
        <div>
          <img className="float-left" style={{ display: 'block', margin: 'auto', height: '1.5rem' }} src="/loading.gif" />
          &nbsp;&nbsp;
          Project creation pending...
        </div>
      );
    }

    return (
      <div className="mini-project">
        <h4 dangerouslySetInnerHTML={{ __html: element ? element.attributes.name : '' }} />
        {hintComponent}
        {content}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    completions: state.unit ? state.unit.completions : [],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LearnMiniProjectDefinitionElementComponent);
