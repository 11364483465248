import React from 'react';
import { findDOMNode } from 'react-dom';
import Sortable from 'sortablejs';
import EditAnswerComponent from './edit-answer-component';
import NewAnswerComponent from './new-answer-component';

class EditFormAnswersComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      answers: this.props.answers,
    };
  }

  componentDidMount() {
    const _this = this;
    const node = findDOMNode(this);
    Sortable.create(node, {
      draggable: '.answer-sortable',
      handle: '.sortable-handle',
      ghostClass: 'sortable-ghost',
      onUpdate() {
        const ids = $(this.el.children).map((_, li) => li.dataset.id).toArray();
        const answers = [];
        ids.forEach((id) => {
          const answer = _this.state.answers.find((ans) => ans.id.toString() === id.toString());
          answers.push(answer);
        });
        _this.setState({
          answers,
        });
      },
    });
  }

  render() {
    const answers = [];
    const _this = this;
    const { objectId } = this.props;
    const { objectType } = this.props;
    let answerERrrors;
    if (this.props.errors) {
      answerERrrors = this.props.errors.map((error, i) => <div key={i} className="alert alert-danger">{error}</div>);
    }

    this.state.answers.forEach((answer, index) => {
      if (answer.id) {
        const newId = (new Date().getTime()) + index;
        answers.push(
          <EditAnswerComponent
            answer={answer}
            key={newId}
            objectType={objectType}
            objectId={objectId}
            position={index + 1}
          />,
        );
      } else {
        const newId = (new Date().getTime()) + index;
        answers.push(
          <NewAnswerComponent
            answer={answer}
            answerId={newId}
            key={newId}
            objectType={objectType}
            objectId={objectId}
            onRemove={_this.removeAnswer}
            answersCount={_this.state.answersCount + 1}
            position={index + 1}
          />,
        );
      }
    });
    return (
      <div>
        {answerERrrors}
        {answers}
      </div>
    );
  }
}

export default EditFormAnswersComponent;
