import React from 'react';
import NewAnswerComponent from './new-answer-component.jsx';

class AddNewAnswersComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      answersCount: this.props.answersCount,
      newAnswers: [],
    };
    this.addAnswer = this.addAnswer.bind(this);
    this.removeAnswer = this.removeAnswer.bind(this);
  }

  removeAnswer(id) {
    const answerComponent = this.state.newAnswers.filter((answer) => parseInt(answer.key, 10) === id)[0];
    const newAnswers = this.state.newAnswers.filter((comp) => comp !== answerComponent);
    this.setState(() => ({ newAnswers }));
  }

  addAnswer() {
    const newId = (new Date().getTime());
    const { objectId } = this.props;
    const { objectType } = this.props;
    const newAnswer = (
      <NewAnswerComponent
        answerId={newId}
        key={newId}
        objectType={objectType}
        objectId={objectId}
        onRemove={this.removeAnswer}
        position={this.state.answersCount + 1}
      />
    );
    this.setState((prevState) => ({ newAnswers: prevState.newAnswers.concat([newAnswer]) }));
  }

  render() {
    const defaultText = this.state.answersCount > 0 ? 'Add another answer' : 'Add an answer';
    const text = (this.props.text || defaultText);
    return (
      <div>
        {this.state.newAnswers}
        <a onClick={this.addAnswer} className="btn btn-secondary btn-sm answer-add-link">
          {text}
        </a>
      </div>
    );
  }
}

export default AddNewAnswersComponent;
