import React from 'react';
import { render } from 'react-dom';
import EditFormAnswersComponent from './edit-answers-component';
import AddNewAnswersComponent from './answers-add-component';

class FormAnswersComponent extends React.Component {
  constructor(props) {
    super(props);
    let { answers } = this.props;
    if (typeof (this.props.answers) === 'string') {
      answers = JSON.parse(this.props.answers);
    }
    this.state = {
      answers,
    };
  }

  render() {
    const addNewAnswersComponent = (
      <AddNewAnswersComponent
        objectType={this.props.objectType}
        objectId={this.props.objectId}
        answersCount={this.state.answers.length}
        text={this.props.text}
      />
    );

    return (
      <div>
        <EditFormAnswersComponent
          answers={this.state.answers}
          objectType={this.props.objectType}
          objectId={this.props.objectId}
          errors={this.props.errors}
        />
        {addNewAnswersComponent}
      </div>
    );
  }
}

$(document).on('turbo:load', () => {
  const el = document.getElementById('answer-component');
  if (el) {
    const objectType = el.dataset.object_type;
    const objectId = el.dataset.object_id;
    const { answers } = el.dataset;
    const { text } = el.dataset;
    render(
      <FormAnswersComponent
        objectType={objectType}
        objectId={objectId}
        answers={answers}
        text={text}
      />,
      el,
    );
  }
});

export default FormAnswersComponent;
