import swal from 'sweetalert2';

$(document).on('turbo:load', () => {
  $('.project-submit-form').on('submit', (event) => {
    Rails.stopEverything(event);
    swal.fire({
      title: 'Are you sure you want to submit your project?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d30a09',
      cancelButtonColor: '#28c664',
      confirmButtonText: 'Yes, submit it!',
    }).then((result) => {
      if (result.value) {
        $(event.target).off('submit').submit();
      }
    });
  });
});
