import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';
import _ from 'lodash';
import swal from 'sweetalert2';
import LearnUpvoteComponent from '../upvotes/learn-upvote-component';
import * as Actions from '../../../actions_learn';

class LearnQuestionAnswerComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleDestroy = this.handleDestroy.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
  }

  componentDidMount() {
    MathJax.Hub.Queue(['Typeset', MathJax.Hub]);
    Prism.highlightAll();
  }

  componentDidUpdate() {
    MathJax.Hub.Queue(['Typeset', MathJax.Hub]);
    Prism.highlightAll();
  }

  handleDestroy(e) {
    e.preventDefault();
    swal.fire({
      title: 'Are you sure you want to delete this answer?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d30a09',
      cancelButtonColor: '#28c664',
      confirmButtonText: 'Yes, delete it!',
      // cancelButtonText: 'No, thank you',
      // reverseButtons: false,
      focusCancel: true,
    }).then((result) => {
      if (result.value) {
        const { answer } = this.props;
        const payload = {
          url: answer.links.self,
          method: 'DELETE',
          successCallbacks: ['REMOVE_QUESTION_ANSWER', 'SET_INCLUDED_QUESTIONS'],
        };
        this.props.actions.fetch(payload);
      }
    });
  }

  handleEdit(e) {
    e.preventDefault();
    this.props.actions.editQuestionAnswer(this.props.answer);
  }

  render() {
    const timezone = $("meta[name='timezone']").attr('content');
    const { answer } = this.props;
    const { user } = this.props;

    let avatarLink = <img className="avatar" alt={user.attributes.username} src={user.links.avatarMedium} />;
    let usernameLink = user.attributes.username;

    if (user.attributes.profileReadable) {
      let url = answer.links.subscription;
      if (this.props.isLearner) url = user.links.self;
      avatarLink = <a href={url}>{avatarLink}</a>;
      usernameLink = <a href={url}>{usernameLink}</a>;
    }

    const adminLinks = [];
    if (answer.attributes.updatable) {
      adminLinks.push(
        <a href="#" key="1" onClick={this.handleEdit}>
          Edit
        </a>,
      );
    }
    if (answer.attributes.destroyable) {
      adminLinks.push(
        <a href="#" key="2" onClick={this.handleDestroy}>
          Destroy
        </a>,
      );
    }
    if (answer.links.convert) {
      adminLinks.push(<a href={answer.links.convert} key="3">Convert to question</a>);
    }
    const adminLinkElements = [];
    _.compact(adminLinks).forEach((link) => {
      adminLinkElements.push(' · ');
      adminLinkElements.push(link);
    });
    const upvoteComponent = (
      <LearnUpvoteComponent
        upvotesCount={answer.attributes.upvotesCount}
        upvotable={answer}
        upvoted={answer.attributes.upvoted}
      />
    );

    let timeTracker;
    if (!this.props.isLearner) {
      const minutes = answer.attributes.durationInMinutes;
      timeTracker = minutes ? ` · Time spent: ${moment.utc(minutes * 60 * 1000).format('HH:mm')}` : '';
    }

    const corrections = this.props.includedItems.filter((item) => answer.relationships.correction.data && item.id === answer.relationships.correction.data.id);
    let correctionElement;
    if (corrections.length > 0) {
      const correction = _.last(corrections);
      const corrector = this.props.includedItems.find((item) => item.id === correction.relationships.author.data.id && item.type === correction.relationships.author.data.type);
      correctionElement = (
        <a href={correction.links.self} className="link" target="_blank" rel="noopener noreferrer">
          <div className="version-mini pull-right">
            <div className="version-mini-user d-flex">
              <div><img className="version-mini-user-avatar" alt={corrector.attributes.username} src={corrector.links.avatarMedium} /></div>
              Edited on {moment(correction.attributes.doneAt).tz(timezone).format('DD MMM YYYY')}
              <br />
              by {corrector.attributes.username}
            </div>
          </div>
        </a>
      );
    }

    return (
      <div>
        <div className="answer question-answer d-flex" id={`question_answer_${answer.id}`}>
          <div className="d-flex w-100">
            {avatarLink}
            <div className="w-100">
              <small>
                {usernameLink} · {user.attributes.type} · {moment(answer.attributes.createdAt).fromNow()} {timeTracker}
                {adminLinkElements}
              </small>
              <div dangerouslySetInnerHTML={{ __html: answer.attributes.sanitizedTextHtml }} className="question-answer-text" />
              {correctionElement}
            </div>
          </div>
          {upvoteComponent}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    includedItems: state.questionAnswer.includedItems,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LearnQuestionAnswerComponent);
