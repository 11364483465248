window.Utilities = window.Utilities || {};

Utilities.Flash = {
  remove() {
    $('.alert:not(.alert-devise,.persistent)')
      .delay(10000).fadeOut().delay(1000)
      .queue(() => {
        $(this).remove();
      })
      .on('click', () => {
        $(this).fadeOut().delay(1000).queue(() => {
          $(this).remove();
        });
      });
  },

  handleFlashMessagesHeader(node, xhr) {
    const _this = this;
    const rawMessages = xhr.getResponseHeader('X-Flash-Messages');
    if (rawMessages) {
      const jsonMessages = JSON.parse(rawMessages);
      $.each(jsonMessages, (type, text) => {
        _this.setMessage(node, type, text);
      });
    }
  },

  setMessage(node, type, text) {
    const message = { type, text };
    Utilities.FMessages.push(message);
    setTimeout(() => {
      Utilities.FMessages.splice(message, 1);
      node.messages(Utilities.FMessages);
    }, 10000);
    node.messages(Utilities.FMessages);
  },
};

export default Utilities.Flash;
