import React from 'react';
import autosize from 'autosize/dist/autosize.js';
import MarkdownPreviewTextarea from '../markdown-preview-textarea-component';

class FeedbackFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    autosize(document.querySelectorAll('textarea'));
  }

  submitForm(e) {
    e.preventDefault();
    this.props.submitForm(e, this.props.url, this.props.method, this.props.success);
  }

  render() {
    const errors = this.props.errors || {};
    const baseErrors = [];

    if (errors.base) {
      $.each(errors.base, (i, error) => {
        baseErrors.push(<div key={i} className="alert alert-danger mb-2">{error}</div>);
      });
    }

    const descriptionMdInputName = 'feedback[description_md]';
    const descriptionMdInputId = 'feedback_description_md';
    const descriptionMdInputClass = 'feedback_description_md form-control';
    const descriptionMdInputValue = this.props.element ? this.props.element.attributes.descriptionMd : null;
    const descriptionHtmlInputName = 'feedback[description_html]';

    const descriptionField = (
      <MarkdownPreviewTextarea
        label="Text"
        id={descriptionMdInputId}
        className={descriptionMdInputClass}
        mdName={descriptionMdInputName}
        htmlName={descriptionHtmlInputName}
        value={descriptionMdInputValue}
        errors={errors.description_md}
        required="true"
      />
    );

    const buttonText = (this.props.element ? 'Update feedback' : 'Add feedback');
    const titleText = this.props.element ? `Edit “${this.props.element.attributes.fullTitle}”` : 'Add feedback';

    return (
      <div className="card">
        <div className="card-header">
          <h5>{titleText}</h5>
        </div>
        <div className="card-body">
          <form onChange={this.setValue} onSubmit={this.submitForm} className="form-vertical form-full-width">
            {baseErrors}
            {descriptionField}
            <div className="actions">
              <button type="submit" className="btn btn-primary">{buttonText}</button> or <a href="#" className="link" onClick={this.props.cancelForm}>cancel</a>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default FeedbackFormComponent;
