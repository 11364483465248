import _ from 'lodash';

import {
  LOADING_COMMENTS,
  SET_COMMENTS,
  SET_INCLUDED_COMMENTS,
  SET_INCLUDED_ITEMS,
  ADD_COMMENT,
  EDIT_COMMENT,
  CANCEL_EDIT_COMMENT,
  UPDATE_COMMENT,
  REMOVE_COMMENT,
} from '../../actions_learn';

const initialState = {
  loadingComments: false,
  comments: [],
  editComment: null,
  includedItems: [],
};

export default function commentReducer(state = initialState, action) {
  const { payload } = action;

  let comments;
  let newComments;

  switch (action.type) {
    case LOADING_COMMENTS:
      return { ...state, loadingComments: payload };

    case SET_COMMENTS:
      comments = payload.data;
      return { ...state, loadingComments: false, comments };

    case SET_INCLUDED_COMMENTS:
      if (payload.included) {
        newComments = payload.included.filter((item) => _.includes(['comments'], item.type));
        const newCommentIds = _.map(newComments, 'id');
        comments = state.comments.filter((cm) => !_.includes(newCommentIds, cm.id)).concat(newComments);
        return { ...state, comments };
      }
      return state;

    case SET_INCLUDED_ITEMS:
      if (payload.included) {
        const includedItems = state.includedItems.concat(payload.included);
        return { ...state, includedItems };
      }
      return state;

    case ADD_COMMENT:
      newComments = state.comments.filter((cm) => cm.id !== payload.data.id).concat(payload.data);
      return { ...state, comments: newComments };

    case REMOVE_COMMENT:
      comments = state.comments.filter((cm) => cm.id !== payload.data.id);
      return { ...state, comments };

    case EDIT_COMMENT:
      return { ...state, editComment: action.payload };

    case CANCEL_EDIT_COMMENT:
      return { ...state, editComment: null };

    case UPDATE_COMMENT:
      newComments = state.comments.filter((cm) => cm.id !== payload.data.id).concat(payload.data);
      return { ...state, comments: newComments };

    default:
      return state;
  }
}
