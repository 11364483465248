import React from 'react';

class FormMiniprojectDefinitionComponent extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
  }

  submitForm(e) {
    e.preventDefault();
    this.props.submitForm(e, this.props.url, this.props.method, this.props.success);
  }

  render() {
    const errors = this.props.errors || {};

    const nameInputName = 'mini_project_definition[name]';
    const nameInputId = 'mini_project_definition_name';
    let nameInputFormGroupClass = 'form-group';
    let nameInputClass = 'mini_project_definition_name form-control';
    const nameInputValue = this.props.element ? this.props.element.attributes.name : null;
    const nameInputErrors = [];
    if (errors.name) {
      nameInputFormGroupClass += ' has-danger';
      nameInputClass += ' form-control-danger';
      $.each(errors.name, (i, error) => {
        nameInputErrors.push(<div key={i} className="form-control-feedback">{error}</div>);
      });
    }

    const nameField = (
      <input
        type="text"
        id={nameInputId}
        name={nameInputName}
        className={nameInputClass}
        defaultValue={nameInputValue}
        required
      />
    );

    const githubSourceRepositoryPathInputName = 'mini_project_definition[github_source_repository_path]';
    const githubSourceRepositoryPathInputId = 'mini_project_definition_github_source_repository_path';
    let githubSourceRepositoryPathInputFormGroupClass = 'form-group';
    let githubSourceRepositoryPathInputClass = 'mini_project_definition_github_source_repository_path form-control';
    const githubSourceRepositoryPathInputValue = this.props.element ? this.props.element.attributes.githubSourceRepositoryPath : null;
    const githubSourceRepositoryPathInputErrors = [];
    if (errors.github_source_repository_path) {
      githubSourceRepositoryPathInputFormGroupClass += ' has-danger';
      githubSourceRepositoryPathInputClass += ' form-control-danger';
      $.each(errors.github_source_repository_path, (i, error) => {
        githubSourceRepositoryPathInputErrors.push(<div key={i} className="form-control-feedback">{error}</div>);
      });
    }

    const githubSourceRepositoryPathField = (
      <input
        type="text"
        id={githubSourceRepositoryPathInputId}
        name={githubSourceRepositoryPathInputName}
        className={githubSourceRepositoryPathInputClass}
        defaultValue={githubSourceRepositoryPathInputValue}
        required
      />
    );

    const congratulationsMessageInputName = 'mini_project_definition[congratulations_message]';
    const congratulationsMessageInputId = 'mini_project_definition_congratulations_message';
    let congratulationsMessageInputFormGroupClass = 'form-group';
    let congratulationsMessageInputClass = 'mini_project_definition_congratulations_message form-control';
    const congratulationsMessageInputValue = this.props.element ? this.props.element.attributes.congratulationsMessage : null;
    const congratulationsMessageInputErrors = [];
    if (errors.congratulations_message) {
      congratulationsMessageInputFormGroupClass += ' has-danger';
      congratulationsMessageInputClass += ' form-control-danger';
      $.each(errors.congratulations_message, (i, error) => {
        congratulationsMessageInputErrors.push(<div key={i} className="form-control-feedback">{error}</div>);
      });
    }

    const congratulationsMessageField = (
      <input
        type="text"
        id={congratulationsMessageInputId}
        name={congratulationsMessageInputName}
        className={congratulationsMessageInputClass}
        defaultValue={congratulationsMessageInputValue}
        placeHolder="Congratulations! Your project is validated."
      />
    );

    const buttonText = (this.props.element ? 'Update Mini Project Definition' : 'Add Mini Project Definition');
    const titleText = this.props.element ? `Edit “${this.props.element.attributes.fullTitle}”` : 'Add Mini Project Definition';

    return (
      <div className="card">
        <div className="card-header">
          <h5>{titleText}</h5>
        </div>
        <div className="card-body">
          <form onChange={this.setValue} onSubmit={this.submitForm} className="form-vertical form-full-width">
            <div className={nameInputFormGroupClass}>
              <label htmlFor={this.props.id}>
                Name<span className="red_star"> *</span>
              </label>
              {nameField}
              {nameInputErrors}
            </div>

            <div className={githubSourceRepositoryPathInputFormGroupClass}>
              <label htmlFor={this.props.id}>
                GitHub repository path<span className="red_star"> *</span>
              </label>
              {githubSourceRepositoryPathField}
              {githubSourceRepositoryPathInputErrors}
            </div>

            <div className={congratulationsMessageInputFormGroupClass}>
              <label htmlFor={this.props.id}>
                Congratulation message
              </label>
              {congratulationsMessageField}
              {congratulationsMessageInputErrors}
            </div>

            <div className="actions">
              <button type="submit" className="btn btn-primary">{buttonText}</button> or <a href="#" className="link" onClick={this.props.cancelForm}>cancel</a>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default FormMiniprojectDefinitionComponent;
