import React from 'react';
import { render } from 'react-dom';

class YoutubePlayer extends React.Component {
  render() {
    // https://developers.google.com/youtube/player_parameters
    // autoplay: 0, rel: 0, modestbranding: 1
    return (
      <iframe
        className="video-player"
        src={`https://www.youtube.com/embed/${this.props.videoId}?autoplay=0&rel=0&modestbranding=1`}
        frameBorder="0"
        allow="fullscreen"
        allowfullscreen="true"
        mozallowfullscreen="true"
        webkitallowfullscreen="true"
        title="YouTube video player"
        width="640"
        height="360"
      />
    );
  }
}

$(document).on('turbo:load', () => {
  $('.video-player').each((index, element) => {
    const videoId = $(element).data('video_id');
    render(<YoutubePlayer videoId={videoId} />, document.getElementById(element.id));
  });
});

export default YoutubePlayer;
