import consumer from '../../channels/consumer';
import Flash from '../flash';

window.Utility = window.Utility || {};

Utility.RefreshSubscriptions = {
  set() {
    if ((document.getElementById('learner-subscriptions') || document.getElementById('learner-subscription')) && !App.subscriptionsChannel) {
      this.setupSubscription();
    }
  },

  setupSubscription() {
    App.subscriptionsChannel = consumer.subscriptions.create('SubscriptionsChannel', {
      received(data) {
        const $subscription = $(`#learn-subscription_${data.subscription_id}`);
        if ($subscription.length) {
          Flash.setMessage(window.flashDiv, 'notice', 'Subscription status has changed');
          window.location.reload();
        }
      },
    });
  },
};

$(document).on('turbo:load', () => {
  Utility.RefreshSubscriptions.set();
});
