import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import autosize from 'autosize/dist/autosize.js';
import * as Actions from '../../actions';
import FormTextQuizzComponent from './form-text-quizz-component';
import ElementAdminButtonComponent from '../elements/element-admin-button-component';

class TextQuizzElementComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      answer: null,
      message: null,
      errors: null,
      success: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.cancelForm = this.cancelForm.bind(this);
  }

  componentDidMount() {
    autosize(document.querySelectorAll('textarea'));
  }

  componentDidUpdate() {
    MathJax.Hub.Queue(['Typeset', MathJax.Hub]);
    Prism.highlightAll();
  }

  handleChange(event) {
    this.setState({
      answer: event.target.value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    const _this = this;
    const { element } = this.props;
    const { answer } = this.state;
    const url = element.links.textQuizzAnswer;
    if (this.props.preview) {
      this.setState({
        errors: { base: ['Quiz cannot be submitted in preview mode'] },
        message: 'Quiz cannot be submitted in preview mode',
        success: false,
      });
    } else {
      $.ajax({
        beforeSend(xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')); },
        url,
        type: 'post',
        data: {
          text_quizz_answer: {
            content: answer,
          },
          preview: true,
        },
      }).done(() => {
        _this.setState({
          errors: null,
          message: element.attributes.successMessageHtml,
          success: true,
        });
      }).fail((jqXHR, textStatus, errorThrown) => {
        _this.setState({
          errors: jqXHR.responseJSON ? jqXHR.responseJSON.errors : { Error: [errorThrown] },
          success: false,
        });
      });
    }
  }

  cancelForm(e) {
    if (e) e.preventDefault();
    this.props.actions.cancelElementEditing();
  }

  render() {
    const { element } = this.props;

    const answerInputName = 'text_quizz_answer[content]';
    const answerInputId = 'text_quizz_answer_content';
    const answerFormGroupClass = 'form-group';
    const answerInputClass = 'text_quizz_answer_content form-control';

    const errors = [];

    if (this.state.errors) {
      $.each(this.state.errors, (attr, errs) => {
        let attribute = attr;
        if (attr === 'content') {
          attribute = 'Answer';
        } else if (attr === 'base') {
          attribute = '';
        }
        $.each(errs, (i, err) => {
          errors.push(
            <div key={i} className="alert alert-danger mb-2">
              {attribute} {err}
            </div>,
          );
        });
      });
    }

    let content = (
      <form className="quizz unit-element" onSubmit={this.handleSubmit}>
        <ElementAdminButtonComponent editable={this.props.editable} element={element} unitContentMdStatus={this.props.unit.attributes.githubContentMdStatus} />
        <div dangerouslySetInnerHTML={{ __html: element ? element.attributes.descriptionHtml : '' }} />
        {errors}
        <div className={answerFormGroupClass}>
          <textarea onChange={this.handleChange} id={answerInputId} name={answerInputName} className={answerInputClass} />
        </div>
        <div className="form-group">
          <button type="submit" onClick={this.handleSubmit} className="btn btn-info">
            Submit answer
          </button>
        </div>
      </form>
    );

    if (this.state.success) {
      content = <div className="alert alert-success" dangerouslySetInnerHTML={{ __html: this.state.message }} />;
    }

    if (this.props.editingElement && this.props.editingElement === element) {
      content = (
        <FormTextQuizzComponent
          element={element}
          unit={this.props.unit}
          cancelForm={this.cancelForm}
          url={element.links.self}
          method="patch"
          submitForm={this.props.submitForm}
          success={Actions.UPDATE_UNIT_AND_ELEMENTS}
          errors={this.props.errors}
        />
      );
    }

    return (
      <div>
        {content}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    unit: state.units.unit,
    editingElement: state.elements.editingElement,
    errors: state.forms.errors,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TextQuizzElementComponent);
