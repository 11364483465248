import showdown, { Converter } from 'showdown';

showdown.extension('targetlink', () => [{
  type: 'lang',
  regex: /\[((?:\[[^\]]*]|[^[\]])*)]\([ \t]*<?(.*?(?:\(.*?\).*?)?)>?[ \t]*((['"])(.*?)\4[ \t]*)?\)\{:target=(["'])(\w*)\6}/g,
  replace(wholematch, linkText, url, a, b, title, c, target) {
    let result = `<a href="${url}"`;

    if (typeof title !== 'undefined' && title !== '' && title !== null) {
      let ttl = title.replace(/"/g, '&quot;');
      ttl = showdown.helper.escapeCharacters(ttl, '*_', false);
      result += ` title="${ttl}"`;
    }

    if (typeof target !== 'undefined' && target !== '' && target !== null) {
      result += ` target="${target}"`;
    }

    result += `>${linkText}</a>`;
    return result;
  },
}]);

showdown.extension('ignoreLatex', () => [
  {
    type: 'lang',
    filter(text) {
      const text2 = text.replace(/^¨D¨D(\n(.+\n)+)¨D¨D$/gm, (match, p1) => `<latex data-code="¥¥¥¥${p1}¥¥¥¥">LATEX</latex>`);
      // function replaceSpacesChars(match, offset, string) {
      //   console.log('match', match);
      //   console.log('offset', offset);
      //   console.log('string', string);
      //   return "<latex data-code=\"" + offset + "\">LATEX</latex>"
      // }
      // // return propertyName.replace(/[A-Z]/g, upperToHyphenLower);
      // return text.replace(/\\\\/g, "\\\\\\").replace(/¨D([\S]*)¨D/g, replaceSpacesChars)
      return text2.replace(/¨D(\S+)¨D/g, '<latex data-code="$1">LATEX</latex>');
    },
  },
  // {
  //   type: 'output',
  //   regex: /--line-numbers/g,
  //   replace: ' line-numbers'
  // }
]);

showdown.extension('ignoreCodeBlock', () => [
  {
    type: 'output',
    filter: (text) => {
      // use new shodown's regexp engine to conditionally parse codeblocks
      const left = '<pre><code\\b[^>]*>';
      const right = '</code></pre>';
      const flags = 'g';
      const replacement = (wholeMatch) => wholeMatch.replace(/'&lt;latex data-code="(\S+)"&gt;LATEX&lt;\/latex&gt;'/g, "'$$$1$$'")
        .replace(/&lt;latex data-code="(\S+)"&gt;LATEX&lt;\/latex&gt;/g, '$$$1$$');
      return showdown.helper.replaceRecursiveRegExp(text, replacement, left, right, flags);
    },
  },
]);

const simpleConverter = new Converter({
  literalMidWordUnderscores: true,
  tables: true,
  extensions: ['targetlink', 'ignoreLatex', 'ignoreCodeBlock'],
  emoji: true,
  tasklists: true,
  strikethrough: true,
});

showdown.extension('calloutBoxes', () => [
  {
    type: 'lang',
    filter(text) {
      return text.replace(/\{:\.call-out-(\w+)\}\n([.|\S|\s]+?)\n\{:\/\}?/gm, (match, p1, p2) => {
        const markup = `<div class='call-out-${p1}'>${simpleConverter.makeHtml(p2)}</div>`;
        return markup;
      });
    },
  },
]);

const LatexResistentMarkdownConverter = {
  convert(markdown) {
    const converter = new Converter({
      literalMidWordUnderscores: true,
      tables: true,
      // openLinksInNewWindow: true,
      extensions: ['targetlink', 'ignoreLatex', 'ignoreCodeBlock', 'calloutBoxes'],
      emoji: true,
      simpleLineBreaks: true,
      tasklists: true,
      strikethrough: true,
    });
    // converter.useExtension("ignoreLatex");
    const html = converter.makeHtml(markdown);
    // debugger
    return html.replace(/<latex data-code="¥¥¥¥([^¥]+)¥¥¥¥">LATEX<\/latex>/g, '$$$$$1$$$$').replace(/<latex data-code="(\S+)">LATEX<\/latex>/g, '$$$1$$');
  },
};
export default LatexResistentMarkdownConverter;
