import { SET_FORM_ERROR, CLEAR_FORM_ERRORS } from '../../actions_learn';

const initialState = {
  errors: [],
  disable: 'false',
};

export default function formsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FORM_ERROR:
      return { ...state, errors: action.payload.errors };

    case CLEAR_FORM_ERRORS: {
      const { errors } = state;
      errors[action.payload] = null;
      return { ...state, errors };
    }

    default:
      return state;
  }
}
