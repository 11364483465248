import { Controller } from '@hotwired/stimulus';
import Webcam from 'webcam-easy';

export default class extends Controller {
  static get targets() {
    return ['webcam', 'canvas', 'webcamPart', 'selfiePart', 'selfieImage', 'selfiePicture'];
  }

  connect() {
    const webcamElement = this.webcamTarget;
    const canvasElement = this.canvasTarget;
    this.webcam = new Webcam(webcamElement, 'user', canvasElement);
    this.webcam.stop();
    this.webcam.start().catch((error) => { $('#webcam-error').html(error).show(); });
  }

  disconnect() {
    this.webcam.stop();
    const videoElements = document.getElementsByTagName('video');
    if (videoElements.length > 0 && videoElements[0].srcObject) {
      const tracks = videoElements[0].srcObject.getTracks();
      tracks.forEach((track) => track.stop());
    }
  }

  capture() {
    const image = this.webcam.snap();
    this.selfieImageTarget.src = image;
    this.selfiePictureTarget.value = image;
    $(this.webcamPartTarget).hide();
    $(this.selfiePartTarget).show();
  }

  retake() {
    this.webcam.start();
    this.selfieImageTarget.src = null;
    this.selfiePictureTarget.value = null;
    $(this.webcamPartTarget).show();
    $(this.selfiePartTarget).hide();
  }
}
