import React from 'react';
import _ from 'lodash';
import HintComponent from '../../hint-component';
import QuizzCheckboxComponent from '../../quizz-checkbox-component';

class LearnQuizzMultipleChoiceComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      answerIds: props.element.attributes.subscriptionLastAnswerIds ? props.element.attributes.subscriptionLastAnswerIds : [],
      message: props.element.attributes.grading ? props.element.attributes.grading.message : null,
      hints: props.element.attributes.grading ? props.element.attributes.grading.hints : [],
      answersSuccessMessages: props.element.attributes.answers_success_messages ? props.element.attributes.grading.answers_success_messages : {},
      success: props.element.attributes.grading ? props.element.attributes.grading.success : false,
      submitting: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
  }

  handleChange(event) {
    const input = event.target;
    const newValue = this.state.answerIds;
    const inputValue = parseInt(input.value, 10);
    if (input.checked) {
      newValue.push(inputValue);
    } else {
      const pos = newValue.indexOf(inputValue);
      newValue.splice(pos, 1);
    }
    this.setState(() => ({ answerIds: newValue }));
  }

  handleSuccess(truthy) {
    this.props.handleSuccess(this.props.element.id, truthy);
  }

  handleSubmit(event) {
    event.preventDefault();
    const _this = this;
    const { element } = this.props;
    const url = element.links.grader;
    const { answerIds } = this.state;
    _this.setState(() => ({
      submitting: true,
    }));
    setTimeout(() => {
      $.ajax({
        beforeSend(xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')); },
        url,
        type: 'post',
        data: {
          quizz_multiple_choice_grader: {
            answer_ids: answerIds,
          },
        },
      }).done((response) => {
        _this.handleSuccess(true);
        _this.setState(() => ({
          message: response.message,
          hints: response.hints,
          answersSuccessMessages: response.answers_success_messages,
          success: true,
          submitting: false,
        }));
      }).fail((data) => {
        _this.handleSuccess(false);
        const { hints } = data.responseJSON;
        const { message } = data.responseJSON;
        _this.setState(() => ({
          message,
          hints,
          answersSuccessMessages: data.responseJSON.answers_success_messages,
          success: false,
          submitting: false,
        }));
      });
    }, 300);
  }

  render() {
    const { element } = this.props;
    const answersData = element.attributes.answers;
    const answersCheckBoxes = [];
    const { hints } = this.state;
    const { answerIds } = this.state;
    const { answersSuccessMessages } = this.state;
    _.sortBy(answersData, ['position', 'id']).forEach((answer, index) => {
      const checked = _.includes(answerIds, answer.id);
      answersCheckBoxes.push(
        <QuizzCheckboxComponent
          answer={answer}
          key={index}
          hint={hints[answer.id]}
          answerMessage={answersSuccessMessages[answer.id]}
          checked={checked}
        />,
      );
    });

    let hintComponent;
    if (!this.state.submitting && (this.state.message || hints.general)) {
      hintComponent = <HintComponent message={this.state.message} hints={hints.general ? [hints.general] : []} success={this.state.success} />;
    }

    let button = (
      <button type="submit" className="btn btn-primary">
        Submit
      </button>
    );
    if (this.state.submitting) {
      button = <img style={{ display: 'block', height: '3rem' }} src="/loading.gif" />;
    }

    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: element.attributes.descriptionHtml }} />
        {hintComponent}
        <form className="quizz form" onSubmit={this.handleSubmit} onChange={this.handleChange}>
          {answersCheckBoxes}
          <div className="form-group">
            {button}
          </div>
        </form>
      </div>
    );
  }
}

export default LearnQuizzMultipleChoiceComponent;
