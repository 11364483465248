import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import autosize from 'autosize/dist/autosize.js';
import * as Actions from '../../../actions_learn';

class LearnFeedbackElementComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: null,
      success: false,
      errors: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { element } = this.props;
    this.setState({
      element,
      text: null,
      success: false,
      errors: null,
    });
    autosize(document.querySelectorAll('textarea'));
  }

  componentDidUpdate() {
    MathJax.Hub.Queue(['Typeset', MathJax.Hub]);
    Prism.highlightAll();
  }

  handleChange(event) {
    const input = event.target;
    const text = input.value;
    this.setState({ text });
  }

  handleSubmit(event) {
    event.preventDefault();
    const _this = this;
    const { element } = this.state;
    const url = element.links.feedbackAnswers;
    $.ajax({
      beforeSend(xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')); },
      url,
      type: 'post',
      data: {
        feedback_answer: { text: this.state.text },
      },
    }).done(() => {
      _this.setState(() => ({
        errors: null,
        success: true,
      }));
    }).fail((data) => {
      const { errors } = data.responseJSON;
      _this.setState(() => ({
        errors,
        success: false,
      }));
    });
  }

  render() {
    const { element } = this.state;

    const errors = this.state.errors || {};
    const errorsElements = [];

    $.each(errors, (attr, errs) => {
      let attribute = attr;
      if (attr === 'content') {
        attribute = 'Answer';
      } else if (attr === 'base') {
        attribute = '';
      }
      $.each(errs, (i, err) => {
        errorsElements.push(
          <div key={attr + i} className="alert alert-danger mb-2">
            <div className="help-block">
              {attribute} {err}
            </div>
          </div>,
        );
      });
    });

    const textInputName = 'feedback_answer[text]';

    let form = (
      <div className="card">
        <div className="card-body">
          <div dangerouslySetInnerHTML={{ __html: element ? element.attributes.descriptionHtml : '' }} />
          <form onSubmit={this.handleSubmit} onChange={this.handleChange} className="form-vertical form-full-width">
            {errorsElements}
            <div className="form-group">
              <textarea name={textInputName} className="form-control" />
            </div>
            <div className="form-group">
              <button type="submit" className="btn btn-primary">Send feedback</button>
            </div>
          </form>
        </div>
      </div>
    );

    if (this.state.success) {
      form = (
        <div className="flash-bar success">
          <div className="content">
            <div className="flash-bar__info">
              Thank you for your feedback :-)
            </div>
          </div>
        </div>
      );
    }

    return (form);
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(mapDispatchToProps)(LearnFeedbackElementComponent);
