import _ from 'lodash';

import {
  SET_INCLUDED_USERS,
} from '../../actions_learn';

const initialState = {
  users: [],
};

export default function userReducer(state = initialState, action) {
  const { payload } = action;

  switch (action.type) {
    case SET_INCLUDED_USERS: {
      if (!payload.included) { return state; }
      const newUsers = payload.included.filter((item) => _.includes(['teachers', 'adminusers', 'learners'], item.type));
      const newUserIds = _.map(newUsers, 'id');
      const users = state.users.filter((u) => !_.includes(newUserIds, u.id)).concat(newUsers);
      return { ...state, users };
    }

    default:
      return state;
  }
}
