import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../actions';
import FormMiniprojectDefinitionComponent from './form-mini-project-definition-component';
import ElementAdminButtonComponent from '../elements/element-admin-button-component';

class MiniprojectDefinitionElementComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { };
    this.cancelForm = this.cancelForm.bind(this);
  }

  cancelForm(e) {
    if (e) e.preventDefault();
    this.props.actions.cancelElementEditing();
  }

  render() {
    const { element } = this.props;
    let content;

    if (element) {
      const workflows = [];
      $.each(element.attributes.subscriptionWorkflows, (i, w) => {
        let state;
        switch (w.state) {
          case 'action_required':
          case 'startup_failure':
            state = <i className="fas fa-exclamation-triangle text-danger" />; break;
          case 'cancelled':
          case 'timed_out':
          case 'failure':
            state = <i className="fas fa-circle-xmark text-danger" />; break;
          case 'success':
            state = <i className="fas fa-circle-check text-success" />; break;
          case 'neutral':
          case 'not_yet_started':
            state = <i className="fas fa-square text-secondary" />; break;
          case 'pending':
          default:
            state = <i className="fas fa-solid fa-cog fa-spin text-info" />;
        }
        workflows.push(<li key={i}>{state} <a href={w.url}>{w.name}</a></li>);
      });

      let repoPath;
      if (element && element.attributes.subscriptionRepositoryPath) {
        const url = `https://github.com/${element.attributes.subscriptionRepositoryPath}`;
        repoPath = (<a href={url}>Your GitHub repository</a>);
      } else {
        repoPath = 'GitHub Repository creation pending...';
      }

      content = (
        <div className="unit-element">
          <div className="mini-project">
            <ElementAdminButtonComponent editable={this.props.editable} element={element} unitContentMdStatus={this.props.unit.attributes.githubContentMdStatus} />
            <h4 dangerouslySetInnerHTML={{ __html: element ? element.attributes.name : '' }} />
            <div className="row">
              <div className="col-6">
                <ul style={{ listStyleType: 'none' }}>
                  {workflows}
                </ul>
              </div>
              <div className="col-6">
                {repoPath}
                <br />
                Nothing has been pushed yet.
                <div className="status">Project not yet started.</div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (this.props.editingElement && this.props.editingElement === element) {
      content = (
        <FormMiniprojectDefinitionComponent
          element={element}
          unit={this.props.unit}
          cancelForm={this.cancelForm}
          url={element.links.self}
          method="patch"
          submitForm={this.props.submitForm}
          success={Actions.UPDATE_UNIT_AND_ELEMENTS}
          errors={this.props.errors}
        />
      );
    }

    return (
      <div>
        {content}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    unit: state.units.unit,
    editingElement: state.elements.editingElement,
    errors: state.forms.errors,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MiniprojectDefinitionElementComponent);
