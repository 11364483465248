import React from 'react';
import autosize from 'autosize/dist/autosize.js';
import MarkdownPreviewTextarea from '../markdown-preview-textarea-component';
import LatexResistentMarkdownConverter from '../../utilities/latex-resistent-markdown-converter.js';

class FormTextQuizzComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      successMessageHtml: '',
    };
    this.submitForm = this.submitForm.bind(this);
    this.setValue = this.setValue.bind(this);
  }

  componentDidMount() {
    autosize(document.querySelectorAll('textarea'));
    // let descriptionHtml = LatexResistentMarkdownConverter.convert(this.props.element ? this.props.element.attributes.descriptionMd : '');
    // let successMessageHtml = LatexResistentMarkdownConverter.convert(this.props.element ? this.props.element.attributes.successMessageMd : '');
    this.setState({
      successMessageHtml: this.props.element ? this.props.element.attributes.successMessageHtml : null,
    });
  }

  setValue(e) {
    if (e.target.id === 'text_quizz_success_message_md') {
      const successMessageHtml = LatexResistentMarkdownConverter.convert(e.target.value);
      this.setState(
        {
          successMessageHtml,
        },
      );
    }
  }

  submitForm(e) {
    e.preventDefault();
    this.props.submitForm(e, this.props.url, this.props.method, this.props.success);
  }

  render() {
    const errors = this.props.errors || {};

    const baseErrors = [];

    if (errors.base) {
      $.each(errors.base, (i, error) => {
        baseErrors.push(<div key={i} className="alert alert-danger mb-2">{error}</div>);
      });
    }

    const descriptionMdInputName = 'text_quizz[description_md]';
    const descriptionMdInputId = 'text_quizz_description_md';
    const descriptionMdInputClass = 'text_quizz_description_md form-control';
    const descriptionMdInputValue = this.props.element ? this.props.element.attributes.descriptionMd : null;
    const descriptionHtmlInputName = 'text_quizz[description_html]';

    const descriptionField = (
      <MarkdownPreviewTextarea
        label="Text"
        id={descriptionMdInputId}
        className={descriptionMdInputClass}
        mdName={descriptionMdInputName}
        htmlName={descriptionHtmlInputName}
        value={descriptionMdInputValue}
        errors={errors.description_md}
        required="true"
      />
    );

    const successMessageMdInputName = 'text_quizz[success_message_md]';
    const successMessageMdInputId = 'text_quizz_success_message_md';
    const successMessageMdInputClass = 'text_quizz_success_message_md form-control';
    const successMessageMdInputValue = this.props.element ? this.props.element.attributes.successMessageMd : null;
    const successMessageHtmlInputName = 'text_quizz[success_message_html]';

    const successMessageField = (
      <MarkdownPreviewTextarea
        label="Success message (Message shown when the quizz is answered)"
        id={successMessageMdInputId}
        className={successMessageMdInputClass}
        mdName={successMessageMdInputName}
        htmlName={successMessageHtmlInputName}
        value={successMessageMdInputValue}
        errors={errors.success_message_md}
        required="true"
      />
    );

    const buttonText = `${this.props.element ? 'Update quizz' : 'Add quizz'} (free text)`;
    const titleText = this.props.element ? `Edit “${this.props.element.attributes.fullTitle}”` : 'Add quizz (free text)';

    return (
      <div className="card">
        <div className="card-header">
          <h5>{titleText}</h5>
        </div>
        <div className="card-body">
          <form onChange={this.setValue} onSubmit={this.submitForm} className="form-vertical form-full-width">
            {baseErrors}
            {descriptionField}
            {successMessageField}
            <input type="hidden" name={successMessageHtmlInputName} defaultValue={this.state.successMessageHtml} />
            <div className="actions">
              <button className="btn btn-primary">{buttonText}</button> or <a href="#" className="link" onClick={this.props.cancelForm}>cancel</a>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default FormTextQuizzComponent;
