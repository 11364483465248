import { Controller } from '@hotwired/stimulus';
import intlTelInput from 'intl-tel-input';

export default class extends Controller {
  connect() {
    $('.phone-input').each((_index, element) => {
      intlTelInput(element, {
        initialCountry: 'ch',
        preferredCountries: ['ch'],
      });
    });
  }
}
