import React from 'react';
import _ from 'lodash';
import autosize from 'autosize/dist/autosize.js';
import MarkdownPreviewTextarea from '../markdown-preview-textarea-component';

class NewAnswerComponent extends React.Component {
  constructor(props) {
    super(props);
    this.removeAnswer = this.removeAnswer.bind(this);
  }

  componentDidMount() {
    autosize(document.querySelectorAll('textarea'));
  }

  removeAnswer() {
    this.props.onRemove(this.props.answerId);
  }

  render() {
    const { answer } = this.props;
    const id = (new Date().getTime()) + _.random(10000);
    const { objectType } = this.props;

    const textMdInputName = `${objectType}[answers_attributes][${id}][text_md]`;
    const textMdInputId = `${objectType}_answers_attributes_${id}_text_md`;
    const textMdInputClass = `${objectType}_answers_attributes_text_md form-control`;
    const textHtmlInputName = `${objectType}[answers_attributes][${id}][text_html]`;

    const textField = (
      <MarkdownPreviewTextarea
        label="Text"
        id={textMdInputId}
        className={textMdInputClass}
        mdName={textMdInputName}
        htmlName={textHtmlInputName}
        required="true"
      />
    );

    const hintMdInputName = `${objectType}[answers_attributes][${id}][hint_md]`;
    const hintMdInputId = `${objectType}_answers_attributes_${id}_hint_md`;
    const hintMdInputClass = `${objectType}_answers_attributes_hint_md form-control`;
    const hintHtmlInputName = `${objectType}[answers_attributes][${id}][hint_html]`;

    const hintField = (
      <MarkdownPreviewTextarea
        label="Success Message"
        id={hintMdInputId}
        className={hintMdInputClass}
        mdName={hintMdInputName}
        htmlName={hintHtmlInputName}
      />
    );

    const successMessageMdInputName = `${objectType}[answers_attributes][${id}][success_message_md]`;
    const successMessageMdInputId = `${objectType}_answers_attributes_${id}_success_message_md`;
    const successMessageMdInputClass = `${objectType}_answers_attributes_success_message_md form-control`;
    const successMessageHtmlInputName = `${objectType}[answers_attributes][${id}][success_message_html]`;

    const successMessageField = (
      <MarkdownPreviewTextarea
        label="Hint"
        id={successMessageMdInputId}
        className={successMessageMdInputClass}
        mdName={successMessageMdInputName}
        htmlName={successMessageHtmlInputName}
      />
    );

    const correctInputName = `${objectType}[answers_attributes][${id}][correct]`;
    const correctInputId = `${objectType}_answers_attributes_${id}_correct`;
    const correctInputClass = `${objectType}_answers_attributes_correct form-check-input`;

    const positionInputName = `${objectType}[answers_attributes][${id}][position]`;

    const permanentIdInputName = `${objectType}[answers_attributes][${id}][permanent_id]`;

    const correctInput = (
      <input
        type="checkbox"
        id={correctInputId}
        name={correctInputName}
        className={correctInputClass}
        value="1"
        defaultChecked={answer ? answer.correct : null}
      />
    );

    return (
      <fieldset className="answer" onChange={this.setValue}>
        <input type="hidden" name={positionInputName} defaultValue={this.props.position} />
        <input type="hidden" name={permanentIdInputName} defaultValue={id} />
        {textField}
        {hintField}
        {successMessageField}
        <div className="form-check">
          <input type="hidden" name={correctInputName} className={correctInputClass} value="0" />
          {correctInput}
          <label htmlFor={correctInputId} className="form-check-label">
            Correct
          </label>
        </div>
        <a onClick={this.removeAnswer} className="btn btn-danger btn-sm answer-remove-link">
          Remove answer
        </a>
      </fieldset>
    );
  }
}

export default NewAnswerComponent;
