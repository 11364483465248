import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../actions';

class ElementAdminButtonComponent extends React.Component {
  constructor(props) {
    super(props);
    this.editElement = this.editElement.bind(this);
  }

  editElement(e) {
    e.preventDefault();
    this.props.actions.editElement(this.props.element);
  }

  render() {
    let content;

    if (this.props.editable && this.props.unitContentMdStatus === 'synced') {
      content = (<div />);
      // TODO : uncomment when edit inline feature will be ready
      content = (
        <a
          href="#"
          className="btn btn-sm btn-secondary btn-unit-element-admin"
          onClick={this.editElement}
        >
          Edit
        </a>
      );
    }

    return (
      <div>
        {content}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(ElementAdminButtonComponent);
