import React from 'react';
import { findDOMNode } from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import autosize from 'autosize/dist/autosize.js';
import _ from 'lodash';
import * as Actions from '../../../actions_learn';
import MarkdownPreviewTextareaControlled from '../../markdown-preview-textarea-controlled-component';
import LatexResistentMarkdownConverter from '../../../utilities/latex-resistent-markdown-converter.js';
import TimeTracker from '../time-tracker/time-tracker.jsx';
import Flash from '../../../utilities/flash';

class LearnQuestionAnswerFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textMd: props.questionAnswer ? props.questionAnswer.attributes.textMd : '',
      errors: {},
      disabled: false,
      trackTime: (!this.props.isLearner
        && (!this.props.questionAnswer || this.props.questionAnswer.attributes.timetrackable)),
    };

    if (this.state.trackTime) {
      this.state.duration = props.questionAnswer && props.questionAnswer.attributes.durationInMinutes ? props.questionAnswer.attributes.durationInMinutes : 0;
    }

    this.submitForm = this.submitForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleTimeTrackingChanged = this.handleTimeTrackingChanged.bind(this);
  }

  componentDidMount() {
    const node = findDOMNode(this);
    autosize(node.querySelectorAll('textarea'));
    if (this.props.questionAnswer) {
      const textHtml = LatexResistentMarkdownConverter.convert(this.props.questionAnswer.attributes.textMd);
      this.setState({
        textHtml,
      });
    }
  }

  handleChange(e) {
    e.preventDefault();
    this.props.actions.clearFormErrors();
    const { target } = e;
    if (target.classList.contains('question_answer_text_md')) {
      const textHtml = LatexResistentMarkdownConverter.convert(target.value);
      this.setState({
        disabled: false,
        errors: {},
        textMd: target.value,
        textHtml,
      });
    } else {
      const { name } = target.dataset;
      const { value } = target;
      const data = {
        errors: {},
        disabled: false,
      };
      data[name] = value;
      this.setState(data);
    }
  }

  handleCancel(e) {
    e.preventDefault();
    if (this.props.questionAnswer) {
      this.props.actions.editQuestionAnswer(null);
    } else {
      this.setState({
        disabled: false,
        errors: {},
        textMd: '',
        textHtml: '',
      });
      this.props.actions.showQuestionAnswerForm({ questionId: null });
    }
  }

  handleTimeTrackingChanged(duration) {
    this.setState({ duration });
  }

  submitForm(e) {
    e.preventDefault();
    const _this = this;
    this.setState({ disabled: true });

    const _data = {
      question_answer: {
        text_md: this.state.textMd,
        text_html: this.state.textHtml,
      },
    };
    if (this.state.trackTime) {
      _data.time_tracker = {
        duration_in_minutes: this.state.duration,
      };
    }

    $.ajax({
      url: this.props.url,
      method: this.props.questionAnswer ? 'PUT' : 'POST',
      data: _data,
    }).done((response, message, xhr) => {
      if (_this.props.questionAnswer) {
        _this.props.actions.updateQuestionAnswer(response);
        _this.props.actions.cancelEditQuestionAnswer(response);
        _this.props.actions.setIncludedItems(response);
      } else {
        _this.props.actions.setIncludedQuestionAnswers(response);
        _this.props.actions.setIncludedUsers(response);
        _this.props.actions.addQuestionAnswer(response);
        _this.props.actions.setIncludedItems(response);
        _this.setState({
          disabled: false,
          textMd: '',
          textHtml: '',
        });
      }
      _this.props.actions.setIncludedQuestions(response);
      _this.props.actions.showQuestionAnswerForm({ questionId: null });
      Flash.handleFlashMessagesHeader(window.flashDiv, xhr);
    }).fail((data) => {
      if (data.responseJSON && data.responseJSON.errors) {
        const { errors } = data.responseJSON;
        _this.setState({
          errors,
          disabled: false,
        });
      }
      Flash.handleFlashMessagesHeader(window.flashDiv, data);
    });
  }

  render() {
    const { errors } = this.state;

    const textMdInputName = 'question_answer[text_md]';
    const textMdInputId = _.compact(['question', this.props.questionId, 'answer', this.props.questionAnswer ? this.props.questionAnswer.id : null, 'text']).join('-');
    const textMdInputClass = 'question_answer_text_md form-control';
    const textHtmlInputName = 'question_answer[text_html]';

    const textField = (
      <MarkdownPreviewTextareaControlled
        label="Your answer"
        labelClassName="col-form-label"
        id={textMdInputId}
        className={textMdInputClass}
        mdName={textMdInputName}
        htmlName={textHtmlInputName}
        valueMd={this.state.textMd}
        valueHtml={this.state.textHtml}
        onChange={this.handleChange}
        errors={errors.text_md}
        required="true"
        isLearner={this.props.isLearner}
        aiSuggestion={this.props.aiSuggestion}
        calendlyUrl={this.props.calendlyUrl}
      />
    );

    let submitText = 'Post answer';
    let submitingText = 'Posting answer';
    let avatarUrl = this.props.currentUserAvatarUrl;
    const cancelLink = (
      <a href="#" className="link" onClick={this.handleCancel}>
        Cancel
      </a>
    );

    if (this.props.questionAnswer) {
      submitText = 'Update answer';
      submitingText = 'Updating answer';
      avatarUrl = this.props.user.links.avatarMedium;
    }

    let timeTracker;
    if (this.state.trackTime) {
      timeTracker = (
        <TimeTracker
          errors={errors}
          duration={this.state.duration}
          handleChange={this.handleTimeTrackingChanged}
          disabled={this.state.disabled}
        />
      );
    }

    return (
      <div className="answer-question d-flex">
        <img className="avatar" src={avatarUrl} />
        <form className="learn-answer-form" onSubmit={this.submitForm} onChange={this.handleChange}>
          {textField}
          <div className="row">
            <div className="col-sm-8">
              {timeTracker}
            </div>
            <div className="col-sm-4 text-right">
              <input type="submit" name="commit" value={submitText} className="btn btn-secondary btn-sm" data-disable-with={submitingText} disabled={this.state.disabled} /> or {cancelLink}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(LearnQuestionAnswerFormComponent);
