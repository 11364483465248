import _ from 'lodash';

import {
  SET_INCLUDED_QUESTION_ANSWERS,
  SET_INCLUDED_ITEMS,
  ADD_QUESTION_ANSWER,
  REMOVE_QUESTION_ANSWER,
  EDIT_QUESTION_ANSWER,
  CANCEL_EDIT_QUESTION_ANSWER,
  UPDATE_QUESTION_ANSWER,
} from '../../actions_learn';

const initialState = {
  questionAnswers: [],
  includedItems: [],
};

export default function questionAnswerReducer(state = initialState, action) {
  const { payload } = action;

  let questionAnswers;
  let newQuestionAnswers;

  switch (action.type) {
    case SET_INCLUDED_QUESTION_ANSWERS: {
      if (payload.included) {
        newQuestionAnswers = payload.included.filter((item) => _.includes(['questionAnswers'], item.type));
        const newQuestionAnswerIds = _.map(newQuestionAnswers, 'id');
        questionAnswers = state.questionAnswers.filter((qa) => !_.includes(newQuestionAnswerIds, qa.id)).concat(newQuestionAnswers);
        return { ...state, questionAnswers };
      }
      return state;
    }

    case SET_INCLUDED_ITEMS: {
      if (payload.included) {
        const includedItems = state.includedItems.concat(payload.included);
        return { ...state, includedItems };
      }
      return state;
    }

    case ADD_QUESTION_ANSWER:
      newQuestionAnswers = state.questionAnswers.filter((qa) => qa.id !== payload.data.id).concat(payload.data);
      return { ...state, questionAnswers: newQuestionAnswers };

    case REMOVE_QUESTION_ANSWER:
      questionAnswers = state.questionAnswers.filter((qa) => qa.id !== payload.data.id);
      return { ...state, questionAnswers };

    case EDIT_QUESTION_ANSWER:
      return { ...state, editQuestionAnswer: action.payload };

    case CANCEL_EDIT_QUESTION_ANSWER:
      return { ...state, editQuestionAnswer: null };

    case UPDATE_QUESTION_ANSWER:
      newQuestionAnswers = state.questionAnswers.filter((qa) => qa.id !== payload.data.id).concat(payload.data);
      return { ...state, questionAnswers: newQuestionAnswers };

    default:
      return state;
  }
}
