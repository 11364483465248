import React from 'react';
import { findDOMNode } from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../actions_learn';

class LearnUpvoteComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleUpvote = this.handleUpvote.bind(this);
  }

  componentDidMount() {
    $(findDOMNode(this)).find('[data-toggle="tooltip"]').tooltip();
  }

  componentDidUpdate() {
    const tooltipText = this.props.upvoted ? 'You upvoted this answer' : 'Upvote this answer';
    const tooltips = $(findDOMNode(this)).find('[data-toggle="tooltip"]');
    tooltips.tooltip('dispose');
    tooltips.tooltip({
      title: tooltipText,
    });
  }

  handleUpvote(e) {
    e.preventDefault();
    const { upvotable } = this.props;
    const method = this.props.upvoted ? 'DELETE' : 'POST';
    const payload = {
      url: upvotable.links.upvote,
      method,
      successCallbacks: ['SET_INCLUDED_QUESTION_ANSWERS'],
    };
    this.props.actions.fetch(payload);
  }

  render() {
    const { upvotable } = this.props;
    let element = (
      <div className="btn btn-secondary" data-toggle="tooltip" data-placement="top" title="You can't upvote this answer">
        <svg className="icon icon-md float-left">
          <use xlinkHref={`${SpritePath}#check`} />
        </svg>
        &nbsp;
        <span>
          {this.props.upvotesCount}
        </span>
      </div>
    );

    if (upvotable.attributes.upvotable) {
      const classes = this.props.upvoted ? 'icon icon-md float-left icon-positive' : 'icon icon-md float-left';
      element = (
        <button
          onClick={this.handleUpvote}
          className="btn btn-secondary"
          type="button"
          data-toggle="tooltip"
          data-placement="top"
          data-title={this.props.upvoted ? 'You upvoted this answer' : 'Upvote this answer'}
        >
          <svg className={classes}>
            <use xlinkHref={`${SpritePath}#check`} />
          </svg>
          &nbsp;
          <span>
            {this.props.upvotesCount}
          </span>
        </button>
      );
    }

    return (<div className="upvote">{element}</div>);
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(LearnUpvoteComponent);
