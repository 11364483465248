import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import LearnUnitButtonComponent from './components/learn/units/learn-unit-button-component';
import LearnQuestionsContainer from './containers/learn/questions';
import LearnQuestionFormComponent from './components/learn/questions/learn-question-form-component';
import LearnGithubProjectDefinitionElementComponent from './components/learn/github-project-definitions/learn-github-project-definition-element-component';
import AutocompleteSearchField from './components/learn/autocomplete-search-field-component';
import BookmarkComponent from './components/learn/bookmarks/bookmark-component';

import {
  renderLearnUnitComponentProvider,
  renderMarkdownPreviewTextarea,
  renderLearnCommentsContainerProvider,
  renderLearnCommentFormComponentProvider,
} from './index_base';

import configureStore from './store/learn/configureStore';

const store = configureStore();

document.addEventListener('turbo:load', () => {
  renderMarkdownPreviewTextarea();

  renderLearnCommentsContainerProvider(store);
  renderLearnCommentFormComponentProvider(store);
  renderLearnUnitComponentProvider(store);

  const unitButtonElement = document.getElementById('learn-unit-button-component');
  if (unitButtonElement) {
    const {
      progressUrl, exercisesCount, deprecatedUnit, neverBlocked,
    } = unitButtonElement.dataset;
    render(
      <Provider store={store}>
        <LearnUnitButtonComponent
          progressUrl={progressUrl}
          exercisesCount={parseInt(exercisesCount, 10)}
          deprecatedUnit={deprecatedUnit === 'true'}
          neverBlocked={neverBlocked === 'true'}
        />
      </Provider>,
      unitButtonElement,
    );
  }

  const bookmarkElement = document.getElementById('bookmark-component');
  if (bookmarkElement) {
    const { bookmarkId } = bookmarkElement.dataset;
    const { url } = bookmarkElement.dataset;
    render(<BookmarkComponent
      bookmarkId={bookmarkId}
      url={url}
    />, bookmarkElement);
  }

  const questionsComponent = document.getElementById('learn-unit-questions-component');
  if (questionsComponent) {
    const { url } = questionsComponent.dataset;
    const { currentUserAvatarUrl } = questionsComponent.dataset;
    const isLearner = (questionsComponent.dataset.isLearner === 'true');
    render(
      <Provider store={store}>
        <LearnQuestionsContainer
          url={url}
          currentUserAvatarUrl={currentUserAvatarUrl}
          isLearner={isLearner}
        />
      </Provider>,
      questionsComponent,
    );
  }

  const githubProjectDefinitionComponent = document.getElementById('learn-github-project-definition-element-component');
  if (githubProjectDefinitionComponent) {
    const { data } = JSON.parse(githubProjectDefinitionComponent.dataset.githubProjectDefinition);
    render(
      <Provider store={store}>
        <LearnGithubProjectDefinitionElementComponent element={data} />
      </Provider>,
      githubProjectDefinitionComponent,
    );
  }

  const questionFormComponent = document.getElementById('learn-unit-question-form-component');
  if (questionFormComponent) {
    const { url } = questionFormComponent.dataset;
    const isLearner = (questionFormComponent.dataset.isLearner === 'true');
    render(
      <Provider store={store}>
        <LearnQuestionFormComponent url={url} isLearner={isLearner} />
      </Provider>,
      questionFormComponent,
    );
  }

  const autocompleteSearchFields = document.getElementsByClassName('autocomplete-search-field');
  [].forEach.call(autocompleteSearchFields, (autocompleteSearchField) => {
    const { url } = autocompleteSearchField.dataset;
    const { value } = autocompleteSearchField.dataset;
    const { name } = autocompleteSearchField.dataset;
    const { placeholder } = autocompleteSearchField.dataset;
    render(<AutocompleteSearchField
      url={url}
      name={name}
      value={value}
      placeholder={placeholder}
    />, autocompleteSearchField);
  });

  if (!window._rails_loaded) {
    Rails.start();
  }
});
