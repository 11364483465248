import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LearnUnitQuestionsComponent from '../../components/learn/units/learn-unit-questions-component';
import * as Actions from '../../actions_learn';

class LearnQuestionsContainer extends React.Component {
  componentDidMount() {
    const payload = {
      url: this.props.url,
      method: 'GET',
      // order matters here with how the components are bound. Setting included items is
      // necessary before setting questions, because the question component makes use of
      // the included items. This suggests that the successCallbacks should trigger one
      // another in a chain rather than collected as a set to be triggered together.
      successCallbacks: ['SET_INCLUDED_QUESTION_ANSWERS', 'SET_INCLUDED_USERS', 'SET_INCLUDED_ITEMS', 'SET_QUESTIONS'],
    };
    this.props.actions.loadingQuestions(true);
    this.props.actions.fetch(payload);
  }

  render() {
    return (
      <LearnUnitQuestionsComponent
        questions={this.props.questions}
        includedItems={this.props.includedItems}
        currentUserAvatarUrl={this.props.currentUserAvatarUrl}
        isLearner={this.props.isLearner}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    questions: state.question.questions,
    includedItems: state.question.includedItems,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LearnQuestionsContainer);
