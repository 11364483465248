import React from 'react';
import autosize from 'autosize/dist/autosize.js';
import FormAnswersComponent from '../answers/answers-form-component';
import MarkdownPreviewTextarea from '../markdown-preview-textarea-component';
import LatexResistentMarkdownConverter from '../../utilities/latex-resistent-markdown-converter.js';

class FormQuizzMultipleChoiceComponent extends React.Component {
  constructor(props) {
    super(props);
    let answers = [];
    if (props.element) {
      answers = props.element.attributes.answers.sort((a, b) => {
        if (a.position !== b.position) {
          return a.position - b.position;
        }
        return a.id - b.id;
      });
    }
    this.state = {
      successMessageHtml: '',
      answers,
    };
    this.submitForm = this.submitForm.bind(this);
    this.setValue = this.setValue.bind(this);
  }

  componentDidMount() {
    autosize(document.querySelectorAll('textarea'));
    // let descriptionHtml = LatexResistentMarkdownConverter.convert(this.props.element ? this.props.element.attributes.descriptionMd : '');
    // let successMessageHtml = LatexResistentMarkdownConverter.convert(this.props.element ? this.props.element.attributes.successMessageMd : '');
    this.setState({
      successMessageHtml: this.props.element ? this.props.element.attributes.successMessageHtml : null,
    });
  }

  setValue(e) {
    if (e.target.id === 'multiple_choice_quizz_success_message_md') {
      const successMessageHtml = LatexResistentMarkdownConverter.convert(e.target.value);
      this.setState(
        {
          successMessageHtml,
        },
      );
    }
  }

  submitForm(e) {
    e.preventDefault();
    this.props.submitForm(e, this.props.url, this.props.method, this.props.success);
  }

  render() {
    const errors = this.props.errors || {};

    const baseErrors = [];

    if (errors.base) {
      $.each(errors.base, (i, error) => {
        baseErrors.push(<div key={i} className="alert alert-danger mb-3">{error}</div>);
      });
    }

    const descriptionMdInputName = 'multiple_choice_quizz[description_md]';
    const descriptionMdInputId = 'multiple_choice_quizz_description_md';
    const descriptionMdInputClass = 'multiple_choice_quizz_description_md form-control';
    const descriptionMdInputValue = this.props.element ? this.props.element.attributes.descriptionMd : null;
    const descriptionHtmlInputName = 'multiple_choice_quizz[description_html]';

    const descriptionField = (
      <MarkdownPreviewTextarea
        label="Description"
        id={descriptionMdInputId}
        className={descriptionMdInputClass}
        mdName={descriptionMdInputName}
        htmlName={descriptionHtmlInputName}
        value={descriptionMdInputValue}
        errors={errors.description_md}
        required="true"
      />
    );

    const successMessageMdInputName = 'multiple_choice_quizz[success_message_md]';
    const successMessageMdInputId = 'multiple_choice_quizz_success_message_md';
    const successMessageMdInputClass = 'multiple_choice_quizz_success_message_md form-control';
    const successMessageMdInputValue = this.props.element ? this.props.element.attributes.successMessageMd : null;
    const successMessageHtmlInputName = 'multiple_choice_quizz[success_message_html]';

    const successField = (
      <MarkdownPreviewTextarea
        label="Success Message"
        id={successMessageMdInputId}
        className={successMessageMdInputClass}
        mdName={successMessageMdInputName}
        htmlName={successMessageHtmlInputName}
        value={successMessageMdInputValue}
        errors={errors.success_message_md}
      />
    );

    const buttonText = `${this.props.element ? 'Update quizz' : 'Add quizz'} (multiple choice)`;
    const titleText = this.props.element ? `Edit “${this.props.element.attributes.fullTitle}”` : 'Add quizz (multiple choice)';

    return (
      <div className="card">
        <div className="card-header">
          <h5>{titleText}</h5>
        </div>
        <div className="card-header">
          <form onChange={this.setValue} onSubmit={this.submitForm} className="form-vertical form-full-width">
            {baseErrors}
            {descriptionField}
            {successField}
            <p className="form-text text-muted">
              Message shown when the quizz is successfully answered
            </p>
            <input type="hidden" name={successMessageHtmlInputName} defaultValue={this.state.successMessageHtml} />
            <h4>Answers</h4>
            <FormAnswersComponent
              objectType="multiple_choice_quizz"
              answers={this.state.answers}
              errors={errors.answers}
            />
            <div className="actions">
              <button className="btn btn-primary">
                {buttonText}
              </button> or <a href="#" className="link" onClick={this.props.cancelForm}>cancel</a>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default FormQuizzMultipleChoiceComponent;
