import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import _ from 'lodash';
import * as Actions from '../../../actions_learn';
import Elements from '../../mixins/elements';
import Flash from '../../../utilities/flash';

import YoutubePlayer from '../../video-player-component';
import LearnQuizzMultipleChoiceComponent from '../quizz-multiple-choices/learn-quizz-multiple-choice-component';
import LearnQuizzSingleChoiceComponent from '../quizz-single-choices/learn-quizz-single-choice-component';
import LearnQuizzTextComponent from '../quizz-texts/learn-quizz-text-component';
import LearnFeedbackComponent from '../feedbacks/learn-feedback-component';

class LearnUnitComponent extends React.Component {
  constructor(props) {
    super(props);
    const unit = JSON.parse(this.props.data);
    let blocks = [];
    let elements = [];
    if (unit.included) {
      blocks = unit.included.filter((item) => item.type === 'blocks').sort((a, b) => a.attributes.position - b.attributes.position);
      const elems = ['pages', 'videos', 'figures', 'singleChoiceQuizzs', 'multipleChoiceQuizzs', 'textQuizzs', 'feedbacks', 'miniProjectDefinitions'];
      elements = unit.included.filter((item) => _.includes(elems, item.type));
    }
    this.state = {
      blocks,
      elements,
      unit: unit.data,
      elementComponents: [],
    };
    this.setElementComponents = this.setElementComponents.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
  }

  componentDidMount() {
    this.setElementComponents();
    this.mountComponents();
  }

  componentDidUpdate() {
    Prism.highlightAll();
    window.MathJax.Hub.Queue(['Typeset', MathJax.Hub]);
  }

  handleSuccess(elementId, truthy) {
    const completions = {
      ...this.props.completions,
      [elementId]: truthy,
    };

    this.props.actions.setCompletionState({ completions });

    const buttonDisabled = _.includes(Object.values(this.props.completions), false);
    if (!buttonDisabled) {
      Flash.setMessage(window.flashDiv, 'success', 'Next unit enabled');
    }
  }

  setElementComponents() {
    const { elements } = this.state;
    const { blocks } = this.state;
    const completions = {};
    const elementComponents = [];

    if (blocks) {
      blocks.forEach((block) => {
        const element = elements.filter((el) => el.type === block.relationships.element.data.type && el.id === block.relationships.element.data.id)[0];
        if (element !== undefined) {
          switch (element.type) {
            case 'videos':
              completions[element.id] = true;
              elementComponents.push(<YoutubePlayer
                videoId={element.attributes.videoId}
                key={block.id}
              />);
              break;
            case 'pages':
              completions[element.id] = true;
              elementComponents.push(<div
                dangerouslySetInnerHTML={{ __html: element.attributes.descriptionHtml }}
                key={block.id}
                className="article"
              />);
              break;
            case 'singleChoiceQuizzs':
              completions[element.id] = (element.attributes.grading ? element.attributes.grading.success : false);
              elementComponents.push(<LearnQuizzSingleChoiceComponent
                element={element}
                key={block.id}
                handleSuccess={this.handleSuccess}
              />);
              break;
            case 'multipleChoiceQuizzs':
              completions[element.id] = (element.attributes.grading ? element.attributes.grading.success : false);
              elementComponents.push(<LearnQuizzMultipleChoiceComponent
                element={element}
                key={block.id}
                handleSuccess={this.handleSuccess}
              />);
              break;
            case 'textQuizzs':
              completions[element.id] = (!!element.attributes.subscriptionLastAnswer);
              elementComponents.push(<LearnQuizzTextComponent
                element={element}
                key={block.id}
                handleSuccess={this.handleSuccess}
              />);
              break;
            case 'figures': {
              completions[element.id] = true;
              const style = {
                maxWidth: '100%',
                width: `${element.attributes.maxWidth ? element.attributes.maxWidth : element.attributes.imageWidth}px`,
              };
              // if (element.attributes.maxWidth) {
              //   style['maxWidth'] = element.attributes.maxWidth + 'px';
              // }
              elementComponents.push(
                <figure key={block.id}>
                  <img src={element.attributes.imageUrl} alt={element.attributes.captionSanitized} style={style} />
                  <figcaption dangerouslySetInnerHTML={{ __html: element.attributes.captionHtml }} />
                </figure>,
              );
              break;
            }
            case 'feedbacks':
              completions[element.id] = true;
              elementComponents.push(<LearnFeedbackComponent
                element={element}
                key={block.id}
              />);
              break;
            default:
              completions[element.id] = true;
              elementComponents.push(
                <p key={block.id}>
                  {block.attributes.type} {block.attributes.fullTitle}
                </p>,
              );
          }
        }
      });
    }
    this.props.actions.setCompletionState({ completions });

    this.setState({
      elementComponents,
    });
  }

  mountComponents() {
    Elements.mount({
      unit: this.state.unit,
      elements: this.state.elements,
      learn: true,
      store: this.props.store,
    });
  }

  unitContent() {
    if (this.state.elementComponents.length > 0) { return this.state.elementComponents; }
    if (this.state.unit.attributes.learnContentHtml) {
      return (<div dangerouslySetInnerHTML={{ __html: this.state.unit.attributes.learnContentHtml }} />);
    }
    return (<p>No content yet</p>);
  }

  render() {
    const content = this.unitContent();
    return (
      <div>{content}</div>
    );
  }
}

function mapStateToProps(state) {
  return {
    completions: state.unit ? state.unit.completions : [],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LearnUnitComponent);
