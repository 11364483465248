import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import _ from 'lodash';
import * as Actions from '../../../actions_learn';
import Flash from '../../../utilities/flash';

import HintComponent from '../../hint-component.jsx';
import QuizzRadioComponent from '../../quizz-radio-component.jsx';

class LearnQuizzSingleChoiceElementComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { submitting: true };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
  }

  componentDidMount() {
    const { element } = this.props;
    this.setState({
      element,
      answerId: element.attributes.subscriptionLastAnswerId,
      message: element.attributes.grading ? element.attributes.grading.message : null,
      hints: element.attributes.grading ? element.attributes.grading.hints : [],
      answersSuccessMessages: element.attributes.answers_success_messages ? element.attributes.grading.answers_success_messages : {},
      success: element.attributes.grading ? element.attributes.grading.success : false,
      submitting: false,
    });
    const completions = { ...this.props.completions, [element.id]: (element.attributes.grading ? element.attributes.grading.success : false) };
    this.props.actions.setCompletionState({ completions });
  }

  componentDidUpdate() {
    MathJax.Hub.Queue(['Typeset', MathJax.Hub]);
    Prism.highlightAll();
  }

  handleChange(event) {
    this.setState({
      answerId: event.target.value,
    });
  }

  handleSuccess(truthy) {
    const completions = {
      ...this.props.completions,
      [this.state.element.id]: truthy,
    };

    this.props.actions.setCompletionState({ completions });

    const buttonDisabled = _.includes(Object.values(this.props.completions), false);
    if (!buttonDisabled) {
      Flash.setMessage(window.flashDiv, 'success', 'Next unit enabled');
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    const _this = this;
    const { element } = this.state;
    const url = element.links.grader;
    const { answerId } = this.state;
    _this.setState(() => ({
      submitting: true,
    }));
    setTimeout(() => {
      $.ajax({
        beforeSend(xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')); },
        url,
        type: 'post',
        data: {
          quizz_single_choice_grader: { answer_id: answerId },
        },
      }).done((response) => {
        _this.handleSuccess(true);
        _this.setState(() => ({
          answerId,
          message: response.message,
          hints: response.hints,
          answersSuccessMessages: response.answers_success_messages,
          success: true,
          submitting: false,
        }));
      }).fail((data) => {
        _this.handleSuccess(false);
        _this.setState(() => ({
          answerId,
          message: data.responseJSON.message,
          hints: data.responseJSON.hints,
          answersSuccessMessages: data.responseJSON.answers_success_messages,
          success: false,
          submitting: false,
        }));
      });
    }, 300);
  }

  render() {
    const _this = this;
    const { element } = this.state;
    const answersData = element ? element.attributes.answers : [];
    const answersRadioButtons = [];
    const { hints } = this.state;
    const { answersSuccessMessages } = this.state;
    _.sortBy(answersData, ['position', 'id']).forEach((answer, index) => {
      answersRadioButtons.push(
        <QuizzRadioComponent
          answer={answer}
          key={index}
          hint={hints[answer.id]}
          answerMessage={answersSuccessMessages[answer.id]}
          onClick={_this.handleChange}
          checked={answer.id === element.attributes.subscriptionLastAnswerId}
        />,
      );
    });

    let hintComponent;
    if (!this.state.submitting && (this.state.message || hints.general)) {
      hintComponent = <HintComponent message={this.state.message} hints={hints.general ? [hints.general] : []} success={this.state.success} />;
    }

    let button = (
      <button type="submit" className="btn btn-primary">
        Submit
      </button>
    );
    if (this.state.submitting) {
      button = <img style={{ display: 'block', height: '3rem' }} src="/loading.gif" />;
    }

    let content;
    if (element) {
      content = (
        <div>
          <div dangerouslySetInnerHTML={{ __html: element ? element.attributes.descriptionHtml : '' }} />
          {hintComponent}
          <form className="quizz" onSubmit={this.handleSubmit}>
            {answersRadioButtons}
            <div className="form-group">
              {button}
            </div>
          </form>
        </div>
      );
    }

    return (
      <div>
        {content}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    completions: state.unit ? state.unit.completions : [],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LearnQuizzSingleChoiceElementComponent);
