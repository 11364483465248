import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import swal from 'sweetalert2';
import moment from 'moment-timezone';
import _ from 'lodash';
import * as Actions from '../../../actions_learn';
import LearnQuestionAnswerComponent from '../question-answers/learn-question-answer-component';
import LearnQuestionAttachmentComponent from './learn-question-attachment-component';
import LearnQuestionAnswerFormComponent from '../question-answers/learn-question-answer-form-component';
import Flash from '../../../utilities/flash';

class LearnQuestionComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleDestroy = this.handleDestroy.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleHide = this.handleHide.bind(this);
    this.showQuestionAnswerForm = this.showQuestionAnswerForm.bind(this);
  }

  componentDidMount() {
    MathJax.Hub.Queue(['Typeset', MathJax.Hub]);
    Prism.highlightAll();
  }

  handleDestroy(e) {
    e.preventDefault();
    swal.fire({
      title: 'Are you sure you want to delete this question?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d30a09',
      cancelButtonColor: '#28c664',
      confirmButtonText: 'Yes, delete it!',
      // cancelButtonText: 'No, thank you',
      // reverseButtons: false,
      focusCancel: true,
    }).then((result) => {
      if (result.value) {
        const { question } = this.props;
        const payload = {
          url: question.links.self,
          method: 'DELETE',
          successCallbacks: ['REMOVE_QUESTION'],
        };
        this.props.actions.fetch(payload);
      }
    });
  }

  handleEdit(e) {
    e.preventDefault();
    this.props.actions.editQuestion(this.props.question);
  }

  handleHide(e) {
    e.preventDefault();
    const _this = this;
    const { question } = this.props;
    $.ajax({
      url: question.links.hide,
      method: 'PUT',
    }).done((response, message, xhr) => {
      _this.props.actions.updateQuestion(response);
      Flash.handleFlashMessagesHeader(window.flashDiv, xhr);
    }).fail((data) => {
      Flash.handleFlashMessagesHeader(window.flashDiv, data);
    });
  }

  showQuestionAnswerForm(e) {
    e.preventDefault();
    const payload = {
      questionId: this.props.question.id,
    };
    this.props.actions.showQuestionAnswerForm(payload);
  }

  render() {
    const _this = this;
    const timezone = $("meta[name='timezone']").attr('content');
    const { question } = this.props;
    const user = this.props.users.filter((item) => parseInt(item.id, 10) === question.attributes.userId)[0];
    let questionAnswers = this.props.questionAnswers.filter((questionAnswer) => questionAnswer.attributes.questionId === parseInt(question.id, 10));

    // questionAnswers = _.orderBy(questionAnswers, ['attributes.upvotesCount', 'attributes.createdAt'], ['desc', 'desc'])
    questionAnswers = _.orderBy(questionAnswers, 'attributes.createdAt', 'asc');

    const answers = [];
    questionAnswers.forEach((answer, index) => {
      const answerUser = _this.props.users.filter((item) => parseInt(item.id, 10) === answer.attributes.userId)[0];
      let element = (
        <LearnQuestionAnswerComponent
          key={index}
          answer={answer}
          user={answerUser}
          isLearner={this.props.isLearner}
        />
      );
      if (this.props.editQuestionAnswer && this.props.editQuestionAnswer.id === answer.id) {
        element = (
          <LearnQuestionAnswerFormComponent
            key={index}
            url={answer.links.self}
            questionAnswer={answer}
            user={answerUser}
            questionId={this.props.question.id}
            isLearner={this.props.isLearner}
            aiSuggestion={this.props.question.attributes.aiAnswerSuggestion}
            calendlyUrl={this.props.question.links.calendlyUrl}
          />
        );
      }
      answers.push(element);
    });

    let info;
    if (question.attributes.sanitizedInfoHtml) {
      info = <div dangerouslySetInnerHTML={{ __html: question.attributes.sanitizedInfoHtml }} className="question-info" />;
    }

    let avatarLink;
    let usernameLink;
    let userType;

    if (user) {
      avatarLink = <img className="avatar" alt={user.attributes.username} src={user.links.avatarMedium} />;
      usernameLink = user.attributes.username;
      userType = user.attributes.type;

      if (user.attributes.profileReadable) {
        let url = question.links.subscription;
        if (this.props.isLearner) url = user.links.self;
        avatarLink = <a href={url}>{avatarLink}</a>;
        usernameLink = <a href={url}>{usernameLink}</a>;
      }
    }

    const adminLinks = [];
    let questionAnswerForm;
    let answerIndicator;

    if (question.attributes.updatable) {
      adminLinks.push(
        <a href="#" key="1" className="link" onClick={this.handleEdit}>
          Edit
        </a>,
      );
    }
    if (question.attributes.destroyable) {
      adminLinks.push(
        <a href="#" key="2" className="link" onClick={this.handleDestroy}>
          Destroy
        </a>,
      );
    }
    if (question.attributes.hidable) {
      adminLinks.push(
        <a href="#" key="3" className="link" onClick={this.handleHide}>
          {question.attributes.hiddenAt !== null ? 'Un-hide' : 'Hide'}
        </a>,
      );
    }

    if (this.props.showingQuestionAnswerForm && this.props.showingQuestionAnswerForm.questionId === question.id) {
      questionAnswerForm = (
        <LearnQuestionAnswerFormComponent
          url={`${question.links.self}/question_answers`}
          currentUserAvatarUrl={this.props.currentUserAvatarUrl}
          questionId={this.props.question.id}
          isLearner={this.props.isLearner}
          aiSuggestion={this.props.question.attributes.aiAnswerSuggestion}
          calendlyUrl={this.props.question.links.calendlyUrl}
        />
      );
    } else {
      questionAnswerForm = (
        <div>
          <div className="answer question-answer text-right" id={`question_${question.id}`}>
            <a href="#" key="3" className="btn btn-secondary btn-sm" onClick={this.showQuestionAnswerForm}>
              Post answer
            </a>
          </div>
        </div>
      );
    }
    if (question.attributes.questionAnswersCount > 0) {
      answerIndicator = (
        <div className="answers-indicator">
          <div>
            <svg className="icon icon-md icon-positive">
              <use xlinkHref={`${SpritePath}#check`} />
            </svg>
            &nbsp;
            <span>
              {question.attributes.questionAnswersCount}
            </span>
          </div>
          <small>{question.attributes.questionAnswersCount === 1 ? 'Answer' : 'Answers'}</small>
        </div>
      );
    }
    const adminLinkElements = [];
    _.compact(adminLinks).forEach((link) => {
      adminLinkElements.push(' · ');
      adminLinkElements.push(link);
    });

    const corrections = this.props.includedItems.filter((item) => question.relationships.correction.data && item.id === question.relationships.correction.data.id);
    let correctionElement;
    if (corrections.length > 0) {
      const correction = _.last(corrections);
      const corrector = this.props.includedItems.find((item) => item.id === correction.relationships.author.data.id && item.type === correction.relationships.author.data.type);
      correctionElement = (
        <a href={correction.links.self} className="link" target="_blank" rel="noopener noreferrer">
          <div className="version-mini pull-right">
            <div className="version-mini-user d-flex">
              <div><img className="version-mini-user-avatar" alt={corrector.attributes.username} src={corrector.links.avatarMedium} /></div>
              Edited on {moment(correction.attributes.doneAt).tz(timezone).format('DD MMM YYYY')}
              <br />
              by {corrector.attributes.username}
            </div>
          </div>
        </a>
      );
    }

    return (
      <div className={question.attributes.hiddenAt !== null ? 'hidden' : ''}>
        <div className="question-container d-flex" id={`question_${question.id}`}>
          <div className="d-flex w-100">
            <p>{avatarLink}</p>
            <div className="w-100">
              <small>
                {usernameLink} · {userType} · {moment(question.attributes.createdAt).fromNow()} {adminLinkElements}
              </small>
              <p className="question">{question.attributes.text}</p>
              {info}
              <LearnQuestionAttachmentComponent attachment={this.props.attachment} />
              {correctionElement}
            </div>
          </div>
          {answerIndicator}
        </div>
        <div className="answers-container">
          {questionAnswerForm}
          {answers}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    showingQuestionAnswerForm: state.question.showingQuestionAnswerForm,
    questionAnswers: state.questionAnswer.questionAnswers,
    editQuestionAnswer: state.questionAnswer.editQuestionAnswer,
    users: state.user.users,
    includedItems: state.question.includedItems,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LearnQuestionComponent);
