import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import SingleChoiceQuizzElementComponent from '../quizz-single-choice/quizz-single-choice-element-component';
import MultipleChoiceQuizzElementComponent from '../quizz-multiple-choice/quizz-multiple-choice-element-component';
import TextQuizzElementComponent from '../text_quizzs/text-quizz-element-component';
import FeedbackElementComponent from '../feedbacks/feedback-element-component';
import MiniProjectDefinitionElementComponent from '../mini-project-definitions/mini-project-definition-element-component';

import LearnQuizzSingleChoiceElementComponent from '../learn/quizz-single-choices/learn-quizz-single-choice-element-component';
import LearnQuizzMultipleChoiceElementComponent from '../learn/quizz-multiple-choices/learn-quizz-multiple-choice-element-component';
import LearnQuizzTextElementComponent from '../learn/quizz-texts/learn-quizz-text-element-component';
import LearnFeedbackElementComponent from '../learn/feedbacks/learn-feedback-element-component';
import LearnMiniProjectDefinitionElementComponent from '../learn/mini-project-definitions/learn-mini-project-definition-element-component';

const Elements = {
  mount(options = {}) {
    const { elements } = options;

    const components = {
      SingleChoiceQuizz: SingleChoiceQuizzElementComponent,
      MultipleChoiceQuizz: MultipleChoiceQuizzElementComponent,
      TextQuizz: TextQuizzElementComponent,
      Feedback: FeedbackElementComponent,
      MiniProjectDefinition: MiniProjectDefinitionElementComponent,
    };

    const learnComponents = {
      SingleChoiceQuizz: LearnQuizzSingleChoiceElementComponent,
      MultipleChoiceQuizz: LearnQuizzMultipleChoiceElementComponent,
      TextQuizz: LearnQuizzTextElementComponent,
      Feedback: LearnFeedbackElementComponent,
      MiniProjectDefinition: LearnMiniProjectDefinitionElementComponent,
    };

    $.each(components, (elementType, component) => {
      const Component = options.learn ? learnComponents[elementType] : component;
      const elementComponents = document.querySelectorAll(`[data-type="${elementType}"]`);

      [].forEach.call(elementComponents, (elementComponent) => {
        const { permanentId } = elementComponent.dataset;
        if (!permanentId) return;

        const element = options.element || elements.find((el) => el.attributes.permanentId === permanentId);

        render(
          <Provider store={options.store}>
            <Component
              element={element}
              permanentId={permanentId}
              key={permanentId}
              editable={options.editable}
              submitForm={options.submitForm}
              preview={options.preview}
              unitSlug={options.unit && options.unit.attributes.slug}
              previewUnit={options.previewUnit}
            />
          </Provider>,
          elementComponent,
        );
      });
    });
  },
};

export default Elements;
