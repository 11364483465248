import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { findDOMNode } from 'react-dom';
import _ from 'lodash';
import * as Actions from '../../../actions_learn';
import LearnCommentComponent from './learn-comment-component';
import LearnCommentFormComponent from './learn-comment-form-component';

class LearnUnitCommentsComponent extends React.Component {
  componentDidMount() {
    this.noHeader = findDOMNode(this).parentNode.className.includes('tab-card');
  }

  componentDidUpdate() {
    MathJax.Hub.Queue(['Typeset', MathJax.Hub]);
    Prism.highlightAll();
  }

  render() {
    const _this = this;
    const commentElements = [];
    const comments = this.props.comments.sort((a, b) => Date.parse(a.attributes.createdAt) - Date.parse(b.attributes.createdAt)).reverse();

    comments.forEach((comment, index) => {
      const user = _this.props.users.find((u) => parseInt(u.id, 10) === comment.attributes.authorId);

      const adminLinks = [];
      if (comment.attributes.updatable) {
        adminLinks.push(<a href="#" key="1" className="link" onClick={this.handleEdit}>Edit</a>);
      }
      if (comment.attributes.destroyable) {
        adminLinks.push(<a href="#" key="2" className="link" onClick={this.handleDestroy}>Destroy</a>);
      }
      const adminLinkElements = [];
      _.compact(adminLinks).forEach((link) => {
        adminLinkElements.push(' · ');
        adminLinkElements.push(link);
      });
      let element = (
        <LearnCommentComponent
          key={index}
          comment={comment}
          user={user}
          currentUserAvatarUrl={this.props.currentUserAvatarUrl}
          isLearner={this.props.isLearner}
          includedItems={this.props.includedItems}
        />
      );

      if (this.props.editComment && parseInt(this.props.editComment.id, 10) === parseInt(comment.id, 10)) {
        element = (
          <LearnCommentFormComponent
            key={index}
            url={comment.links.self}
            comment={comment}
            isLearner={this.props.isLearner}
            calendlyUrl={comment.links.calendlyUrl}
          />
        );
      }
      commentElements.push(element);
    });

    let noCommentsText;
    if (comments.length === 0) {
      if (this.props.loadingComments) {
        noCommentsText = (
          <p className="comments-no-comments">
            <img style={{ display: 'block', margin: 'auto', height: '5rem' }} src="/loading.gif" />
          </p>
        );
      } else {
        noCommentsText = <p className="comments-no-comments">No comments yet</p>;
      }
    }

    let header = '';
    if (this.noHeader !== undefined) {
      header = this.noHeader ? (<h5> </h5>) : (<h5>Comments <i title="visible by learner" className="fas fa-eye" /></h5>);
    }

    return (
      <div className="unit-comments" id="comments">
        <header>
          {header}
          <small>
            <a data-toggle="modal" href="#" data-target="#commentFormModal">Post a Comment</a>
          </small>
        </header>
        {noCommentsText}
        {commentElements}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loadingComments: state.comment.loadingComments,
    comments: state.comment.comments,
    editComment: state.comment.editComment,
    users: state.user.users,
    includedItems: state.comment.includedItems,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LearnUnitCommentsComponent);
