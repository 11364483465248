import React from 'react';

const LearnQuestionAttachmentComponent = function learnQuestionAttachmentComponent(props) {
  const { attachment } = props;

  if (!attachment) {
    return null;
  }

  return (
    <div className="question-attachment">
      <a href={attachment.attributes.url} target="_blank" rel="noopener noreferrer" aria-label="Question Attachment">
        <img src={attachment.attributes.url} className="question-attachment-thumbnail" />
      </a>
    </div>
  );
};

export default LearnQuestionAttachmentComponent;
