import React from 'react';
import { findDOMNode } from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import _ from 'lodash';
import * as Actions from '../../../actions_learn';

class LearnUnitButtonComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonDisabled: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const node = findDOMNode(this);
    $(node).find('[data-toggle="tooltip"]').tooltip();
  }

  componentDidUpdate() {
    const node = findDOMNode(this);
    $(node).find('[data-toggle="tooltip"]').tooltip();
  }

  handleSubmit() {
    this.setState({ buttonDisabled: true });
  }

  render() {
    const nextEnabled = <form action={this.props.progressUrl} method="put" onSubmit={this.handleSubmit}><input type="submit" value="Next" className="btn btn-primary btn-block" /></form>;

    if (this.props.deprecatedUnit || this.props.neverBlocked) {
      return (<div>{nextEnabled}</div>);
    }

    const quizzCompletedFromBlocks = _.includes(Object.values(this.props.completions), false);
    const quizzCompletedFromElements = this.props.exercisesCount > 0 && (_.size(this.props.completions) !== this.props.exercisesCount);
    let button;

    if (quizzCompletedFromBlocks || quizzCompletedFromElements) {
      button = <p className="btn btn-secondary btn-block" data-toggle="tooltip" data-placement="top" title="Complete all quizzes and exercises to enable the next unit">Next</p>;
    } else if (this.state.buttonDisabled) {
      button = <p className="btn btn-secondary btn-block" data-toggle="tooltip" data-placement="top" title="Next unit is loading">Loading…</p>;
    } else {
      button = nextEnabled;
    }

    return (<div>{button}</div>);
  }
}

function mapStateToProps(state) {
  return {
    completions: state.unit ? state.unit.completions : [],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LearnUnitButtonComponent);
