import Cookies from 'js-cookie';

const programNavigation = {
  navigationState: {},

  initializeNavigationState() {
    this.navigationState = {
      courseDetails: 'collapsed',
      subjectDetails: 'collapsed',
      unitDetails: 'expanded',
    };
    if (Cookies.get('programNavigation')) {
      try {
        const currentState = JSON.parse(Cookies.get('programNavigation'));
        this.navigationState = { ...this.navigationState, ...currentState };
      } catch (error) {
        console.log(error);
      }
    } else {
      Cookies.set('programNavigation', JSON.stringify(this.navigationState));
    }
  },

  setNavigationState(state) {
    this.navigationState = { ...this.navigationState, ...state };
    Cookies.set('programNavigation', JSON.stringify(this.navigationState));
  },

  onCollapse() {
    $(`#previousCourseDetails, #nextCourseDetails,
       #previousSubjectDetails, #nextSubjectDetails,
       #previousUnitDetails, #nextUnitDetails`).on('hide.bs.collapse', (event) => {
      const item = $(event.currentTarget).parents('.item');
      const itemId = item.attr('id');
      item.addClass('collapsed');
      const newState = {};
      newState[itemId] = 'collapsed';
      this.setNavigationState(newState);
    });
  },

  onExpand() {
    $(`#previousCourseDetails, #nextCourseDetails,
       #previousSubjectDetails, #nextSubjectDetails,
       #previousUnitDetails, #nextUnitDetails`).on('show.bs.collapse', (event) => {
      const item = $(event.currentTarget).parents('.item');
      const itemId = item.attr('id');
      item.removeClass('collapsed');
      const newState = {};
      newState[itemId] = 'expanded';
      this.setNavigationState(newState);
    });
  },
};

$(document).on('turbo:load', () => {
  programNavigation.initializeNavigationState();
  programNavigation.onCollapse();
  programNavigation.onExpand();
});
