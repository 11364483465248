import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../actions_learn';
import LearnQuestionComponent from '../questions/learn-question-component';
import LearnQuestionFormComponent from '../questions/learn-question-form-component';

class LearnUnitQuestionsComponent extends React.Component {
  render() {
    const _this = this;
    const questionElements = [];
    const questions = this.props.questions.sort((a, b) => Date.parse(a.attributes.createdAt) - Date.parse(b.attributes.createdAt)).reverse();

    questions.forEach((question, index) => {
      const user = _this.props.users.find((u) => parseInt(u.id, 10) === question.attributes.userId);
      let attachment;
      if (question.relationships.attachment.data) {
        [attachment] = this.props.includedItems.filter((item) => item.id === question.relationships.attachment.data.id && item.type === 'attachments');
      }

      let element = (
        <LearnQuestionComponent
          key={index}
          question={question}
          attachment={attachment}
          user={user}
          includedItems={this.props.includedItems}
          currentUserAvatarUrl={this.props.currentUserAvatarUrl}
          isLearner={this.props.isLearner}
        />
      );
      if (this.props.editQuestion && parseInt(this.props.editQuestion.id, 10) === parseInt(question.id, 10)) {
        element = (
          <LearnQuestionFormComponent
            key={index}
            url={question.links.self}
            question={question}
            attachment={attachment}
            isLearner={this.props.isLearner}
          />
        );
      }
      questionElements.push(element);
    });

    let noQuestionsText;
    if (questions.length === 0) {
      if (this.props.loadingQuestions) {
        noQuestionsText = (
          <p>
            <img style={{ display: 'block', margin: 'auto', height: '5rem' }} src="/loading.gif" />
          </p>
        );
      } else {
        noQuestionsText = <p>No questions yet</p>;
      }
    }

    return (
      <div className="unit-questions" id="questions">
        <header>
          <h5>Questions</h5>
          <small>
            <a data-toggle="modal" href="#" data-target="#questionFormModal">
              Ask a Question
            </a>
          </small>
        </header>
        {noQuestionsText}
        {questionElements}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loadingQuestions: state.question.loadingQuestions,
    questions: state.question.questions,
    editQuestion: state.question.editQuestion,
    questionAnswers: state.questionAnswer.questionAnswers,
    users: state.user.users,
    includedItems: state.question.includedItems,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LearnUnitQuestionsComponent);
