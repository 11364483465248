import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import swal from 'sweetalert2';
import moment from 'moment-timezone';
import _ from 'lodash';
import * as Actions from '../../../actions_learn';

class LearnCommentComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleDestroy = this.handleDestroy.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
  }

  handleDestroy(e) {
    e.preventDefault();
    swal.fire({
      title: 'Are you sure you want to delete this comment?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d30a09',
      cancelButtonColor: '#28c664',
      confirmButtonText: 'Yes, delete it!',
      // cancelButtonText: 'No, thank you',
      // reverseButtons: false,
      focusCancel: true,
    }).then((result) => {
      if (result.value) {
        const { comment } = this.props;
        const payload = {
          url: comment.links.self,
          method: 'DELETE',
          successCallbacks: ['REMOVE_COMMENT'],
        };
        this.props.actions.fetch(payload);
      }
    });
  }

  handleEdit(e) {
    e.preventDefault();
    this.props.actions.editComment(this.props.comment);
  }

  render() {
    const timezone = $("meta[name='timezone']").attr('content');
    const { comment } = this.props;
    const user = this.props.users.filter((item) => parseInt(item.id, 10) === comment.attributes.authorId)[0];

    let text;
    if (comment.attributes.sanitizedTextHtml) {
      text = <div dangerouslySetInnerHTML={{ __html: comment.attributes.sanitizedTextHtml }} />;
    }

    let avatarLink;
    let usernameLink;
    let userType;

    if (user) {
      avatarLink = <img className="avatar" alt={user.attributes.username} src={user.links.avatarMedium} />;
      usernameLink = user.attributes.username;
      userType = user.attributes.type;

      if (user.attributes.profileReadable) {
        avatarLink = <a href={user.links.self}>{avatarLink}</a>;
        usernameLink = <a href={user.links.self}>{usernameLink}</a>;
      }
    }

    const adminLinks = [];
    if (comment.attributes.updatable) {
      adminLinks.push(<a href="#" key="1" className="link" onClick={this.handleEdit}>Edit</a>);
    }
    if (comment.attributes.destroyable) {
      adminLinks.push(<a href="#" key="2" className="link" onClick={this.handleDestroy}>Destroy</a>);
    }
    if (comment.attributes.hidable) {
      adminLinks.push(<a href="#" key="3" className="link" onClick={this.handleHide}>{comment.attributes.hiddenAt !== null ? 'Un-hide' : 'Hide'}</a>);
    }

    const adminLinkElements = [];
    _.compact(adminLinks).forEach((link) => {
      adminLinkElements.push(' · ');
      adminLinkElements.push(link);
    });

    let formattedCreatedAt = moment(comment.attributes.createdAt).fromNow();

    let timeTracker;
    if (!this.props.isLearner) {
      const minutes = this.props.comment.attributes.durationInMinutes;
      timeTracker = minutes ? ` · Time spent: ${moment.utc(minutes * 60 * 1000).format('HH:mm')}` : '';
      formattedCreatedAt = moment(comment.attributes.createdAt).format('DD-MM-YYYY HH:mm');
    }

    const correction = this.props.includedItems.find((item) => comment.relationships.correction.data && item.id === comment.relationships.correction.data.id);
    let correctionElement;
    if (correction) {
      const corrector = this.props.includedItems.find((item) => item.id === correction.relationships.author.data.id && item.type === correction.relationships.author.data.type);
      correctionElement = (
        <a href={correction.links.self} className="link" target="_blank" rel="noopener noreferrer">
          <div className="version-mini pull-right">
            <div className="version-mini-user d-flex">
              <div><img className="version-mini-user-avatar" alt={corrector.attributes.username} src={corrector.links.avatarMedium} /></div>
              Edited on {moment(correction.attributes.doneAt).tz(timezone).format('DD MMM YYYY')}
              <br />
              by {corrector.attributes.username}
            </div>
          </div>
        </a>
      );
    }

    return (
      <div className={comment.attributes.hiddenAt !== null ? 'hidden' : ''}>
        <div className="comment-container d-flex" id={`comment_${comment.id}`}>
          <div className="d-flex">
            <p>{avatarLink}</p>
            <div>
              <small>
                {usernameLink} · {userType} · {formattedCreatedAt} {timeTracker} {adminLinkElements}
              </small>
              {text}
              {correctionElement}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: state.user.users,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LearnCommentComponent);
