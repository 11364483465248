import React from 'react';
import { findDOMNode } from 'react-dom';
import autosize from 'autosize/dist/autosize.js';
import _ from 'lodash';

class MarkdownPreviewTextareaControlled extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      preview: false,
    };
    this.setPreview = this.setPreview.bind(this);
    this.insertCalendlyLink = this.insertCalendlyLink.bind(this);
    this.insertAISuggestion = this.insertAISuggestion.bind(this);
  }

  componentDidMount() {
    autosize(findDOMNode(this).querySelectorAll('textarea'));
  }

  componentDidUpdate() {
    Prism.highlightAll();
    autosize(findDOMNode(this).querySelectorAll('textarea'));
    MathJax.Hub.Queue(['Typeset', MathJax.Hub]);
  }

  handleTextAreaChanges(textarea) {
    autosize.update(textarea);
    textarea.dispatchEvent(new Event('change', { bubbles: true }));
    textarea.focus();
  }

  setPreview(e) {
    e.preventDefault();
    const preview = e.target.dataset.preview === 'preview';
    this.setState({
      preview,
    });
  }

  insertCalendlyLink(e) {
    e.preventDefault();
    this.insertText(`[Calendly Link](${this.props.calendlyUrl})`);
  }

  insertAISuggestion(e) {
    e.preventDefault();

    const textarea = findDOMNode(this).querySelector('textarea');
    if (textarea.value !== '' && !window.confirm('Are you sure you want to replace your answer with an AI suggestion?')) {
      return;
    }

    textarea.value = '';
    textarea.setRangeText(this.props.aiSuggestion);
    this.handleTextAreaChanges(textarea);
  }

  insertText(text) {
    const textarea = findDOMNode(this).querySelector('textarea');
    textarea.setRangeText(text, textarea.selectionStart, textarea.selectionEnd, 'select');
    this.handleTextAreaChanges(textarea);
  }

  render() {
    let star;
    const required = this.props.required && this.props.required === 'true';
    if (required) {
      star = <span className="red_star"> *</span>;
    }

    let classes = this.props.className;
    let { labelClassName } = this.props;
    labelClassName += ' float-left';

    const errorElements = [];
    const { errors } = this.props;
    if (errors && !_.isEmpty(errors)) {
      classes += ' has-danger is-invalid';
      $.each(errors, (i, error) => {
        errorElements.push(
          <div key={i} className="form-control-feedback has-danger is-invalid">
            Text {error}
          </div>,
        );
      });
    }

    let adminButtons;
    if (!this.props.isLearner) {
      let aiSuggestion;
      if (this.props.aiSuggestion) {
        aiSuggestion = (
          <span className="toolbar-button" data-md-button onClick={this.insertAISuggestion} title="AI Answer suggestion">
            <img src={window.Exts.OpenAI.iconUrl} />
          </span>
        );
      }

      let calendlyLink;
      if (this.props.calendlyUrl) {
        calendlyLink = (
          <span className="toolbar-button" data-md-button onClick={this.insertCalendlyLink} title="Calendly link">
            <img src={window.Exts.Calendly.iconUrl} />
          </span>
        );
      }

      adminButtons = (
        <div className="float-left">
          {calendlyLink}
          {aiSuggestion}
        </div>
      );
    }

    let area;
    let previewLink;
    let toolbar;

    if (this.state.preview) {
      previewLink = <a href="#" className="link" data-preview="write" onClick={this.setPreview}>Write</a>;
      area = (
        <div className="markdown-preview">
          <div dangerouslySetInnerHTML={{ __html: this.props.valueHtml }} />
        </div>
      );
    } else {
      previewLink = <a href="#" className="link" data-preview="preview" onClick={this.setPreview}>Preview</a>;
      toolbar = (
        <div className="toolbar">
          <markdown-toolbar for={this.props.id}>
            <div className="float-left">
              <md-bold class="toolbar-button" title="Bold text">
                <i className="fas fa-bold" aria-hidden="true" />
              </md-bold>
              <md-header class="toolbar-button" title="Header">
                <i className="fas fa-header" aria-hidden="true" />
              </md-header>
              <md-italic class="toolbar-button" title="Italic text">
                <i className="fas fa-italic" aria-hidden="true" />
              </md-italic>
              <md-strikethrough class="toolbar-button" title="Strikethrough text">
                <i className="fas fa-strikethrough" aria-hidden="true" />
              </md-strikethrough>
              <md-quote class="toolbar-button" title="Quote">
                <i className="fas fa-quote-right" aria-hidden="true" />
              </md-quote>
              <md-code class="toolbar-button" title="Code">
                <i className="fas fa-code" aria-hidden="true" />
              </md-code>
              <md-link class="toolbar-button" title="Link">
                <i className="fas fa-link" aria-hidden="true" />
              </md-link>
              <md-unordered-list class="toolbar-button" title="Bulleted list">
                <i className="fas fa-list" aria-hidden="true" />
              </md-unordered-list>
              <md-ordered-list class="toolbar-button" title="Numbered list">
                <i className="fas fa-list-ol" aria-hidden="true" />
              </md-ordered-list>
              <md-task-list class="toolbar-button" title="Task list">
                <i className="fas fa-check" aria-hidden="true" />
              </md-task-list>
            </div>
            {adminButtons}
          </markdown-toolbar>
        </div>
      );
      area = (
        <div>
          <textarea
            id={this.props.id}
            className={classes}
            name={this.props.mdName}
            value={this.props.valueMd}
            required={required}
            onChange={this.props.onChange}
          />
          {errorElements}
        </div>
      );
    }

    return (
      <div className="form-group markdown-preview-group">
        <label htmlFor={this.props.id} className={labelClassName}>
          {this.props.label}{star} (supports markdown)
        </label>
        {previewLink}
        {toolbar}
        {area}
      </div>
    );
  }
}

export default MarkdownPreviewTextareaControlled;
