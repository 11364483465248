import React from 'react';
import _ from 'lodash';

class AutocompleteSearchField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      units: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.fetchResults = this.fetchResults.bind(this);
    this.fetchResultsWithDebounce = _.debounce(this.fetchResults, 400);
  }

  componentDidMount() {
    $('body').on('click', (event) => {
      if ($(event.target).closest('#adminSearchDropdown').length === 0) {
        $('#adminSearchFieldResult').hide();
      }
    });
    // on escape
    $(document).on('keyup', (e) => {
      if (e.key === 'Escape') {
        $('#adminSearchFieldResult').hide();
      }
    });
  }

  handleChange(event) {
    const { value } = event.target;
    this.setState({
      value,
    });
    if (value === '') {
      $('#adminSearchFieldResult').hide();
      this.setState({
        units: [],
      });
    } else {
      this.fetchResultsWithDebounce();
    }
  }

  handleFocus() {
    $('#adminSearchFieldResult').show();
  }

  fetchResults() {
    const _this = this;
    $.ajax({
      url: _this.props.url,
      method: 'GET',
      dataType: 'json',
      data: {
        term: this.state.value,
      },
    }).done((response) => {
      _this.setState({
        units: response.data,
      });
    }).fail((jqXHR, textStatus, errorThrown) => {
      console.log('ERROR jqXHR', jqXHR);
      console.log('ERROR textStatus', textStatus);
      console.log('ERROR errorThrown', errorThrown);
      if (errorThrown === 'Unauthorized') {
        document.location.reload(true);
      }
    });
  }

  render() {
    let resultElement;
    if (this.state.units.length) {
      $('#adminSearchFieldResult').show();
      const units = [];
      $.each(this.state.units, (i, unit) => {
        units.push(<a href={unit.links.pageSelf} key={unit.id} className="dropdown-item">{unit.attributes.title}</a>);
        units.push(<hr key={i} />);
      });
      units.push(
        <a href={`${this.props.url}?term=${this.state.value}`} key="all" className="dropdown-item d-flex justify-content-between">
          <strong>
            All results
          </strong>
          <span>
            ⮐
          </span>
        </a>,
      );
      resultElement = (
        <div className="dropdown-menu" id="adminSearchFieldResult" style={{ display: 'block' }} aria-labelledby="adminSearchField">
          {units}
        </div>
      );
    } else {
      resultElement = (
        <div className="dropdown-menu dropdown-menu-empty" id="adminSearchFieldResult" aria-labelledby="adminSearchField" />
      );
    }
    return (
      <div id="adminSearchDropdown" className="dropdown">
        <input
          id="adminSearchField"
          className="form-control dropdown-toggle"
          data-toggle="dropdown"
          type="search"
          name={this.props.name}
          defaultValue={this.props.value}
          placeholder={this.props.placeholder || 'Search...'}
          accessKey="s"
          onChange={this.handleChange}
          onFocus={this.handleFocus}
        />
        {resultElement}
      </div>
    );
  }
}

export default AutocompleteSearchField;
