import React from 'react';
// import ReactDom, { render } from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LearnCommentsComponent from '../../components/learn/comments/learn-comments-component';
import * as Actions from '../../actions_learn';

class LearnCommentsContainer extends React.Component {
  componentDidMount() {
    const payload = {
      url: this.props.url,
      method: 'GET',
      successCallbacks: ['SET_INCLUDED_USERS', 'SET_COMMENTS', 'SET_INCLUDED_ITEMS'],
    };
    this.props.actions.loadingComments(true);
    this.props.actions.fetch(payload);
  }

  render() {
    return (
      <LearnCommentsComponent
        comments={this.props.comments}
        currentUserAvatarUrl={this.props.currentUserAvatarUrl}
        isLearner={this.props.isLearner}
        includedItems={this.props.includedItems}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    comments: state.comment.comments,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LearnCommentsContainer);
