import _ from 'lodash';

import {
  LOADING_QUESTIONS,
  SET_QUESTIONS,
  SET_INCLUDED_QUESTIONS,
  SET_INCLUDED_ITEMS,
  ADD_QUESTION,
  EDIT_QUESTION,
  CANCEL_EDIT_QUESTION,
  UPDATE_QUESTION,
  REMOVE_QUESTION,
  REMOVE_QUESTION_ATTACHMENT,
  SHOW_QUESTION_ANSWER_FORM,
} from '../../actions_learn';

const initialState = {
  loadingQuestions: false,
  questions: [],
  editQuestion: null,
  includedItems: [],
};

export default function questionReducer(state = initialState, action) {
  const { payload } = action;

  let questions;
  let newQuestions;

  switch (action.type) {
    case LOADING_QUESTIONS:
      return { ...state, loadingQuestions: payload };

    case SET_QUESTIONS:
      questions = payload.data;
      return { ...state, loadingQuestions: false, questions };

    case SET_INCLUDED_QUESTIONS: {
      if (payload.included) {
        newQuestions = payload.included.filter((item) => _.includes(['questions'], item.type));
        const newQuestionIds = _.map(newQuestions, 'id');
        questions = state.questions.filter((q) => !_.includes(newQuestionIds, q.id)).concat(newQuestions);
        return { ...state, questions };
      }
      return state;
    }

    case SET_INCLUDED_ITEMS: {
      if (payload.included) {
        const includedItems = state.includedItems.concat(payload.included);
        return { ...state, includedItems };
      }
      return state;
    }

    case ADD_QUESTION:
      newQuestions = state.questions.filter((q) => q.id !== payload.data.id).concat(payload.data);
      return { ...state, questions: newQuestions };

    case REMOVE_QUESTION:
      questions = state.questions.filter((q) => q.id !== payload.data.id);
      return { ...state, questions };

    case EDIT_QUESTION:
      return { ...state, editQuestion: payload };

    case CANCEL_EDIT_QUESTION:
      return { ...state, editQuestion: null };

    case SHOW_QUESTION_ANSWER_FORM:
      return { ...state, showingQuestionAnswerForm: payload };

    case UPDATE_QUESTION:
    case REMOVE_QUESTION_ATTACHMENT:
      newQuestions = state.questions.filter((q) => q.id !== payload.data.id).concat(payload.data);
      return { ...state, questions: newQuestions };

    default:
      return state;
  }
}
