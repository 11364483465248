import React from 'react';
import { findDOMNode } from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import autosize from 'autosize/dist/autosize.js';
import ReactS3Uploader from 'react-s3-uploader';
import swal from 'sweetalert2';
import * as Actions from '../../../actions_learn';
import Flash from '../../../utilities/flash';
import MarkdownPreviewTextareaControlled from '../../markdown-preview-textarea-controlled-component';
import LatexResistentMarkdownConverter from '../../../utilities/latex-resistent-markdown-converter.js';

class LearnQuestionFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: props.question ? props.question.attributes.text : '',
      infoMd: props.question ? props.question.attributes.infoMd : '',
      disabled: false,
      errors: {},
    };

    this.submitForm = this.submitForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.onUploadFinish = this.onUploadFinish.bind(this);
    this.onUploadProgress = this.onUploadProgress.bind(this);
    this.onUploadError = this.onUploadError.bind(this);
    this.onClearUploader = this.onClearUploader.bind(this);
    this.preprocessUpload = this.preprocessUpload.bind(this);
    this.onReceivedUploadFilename = this.onReceivedUploadFilename.bind(this);
    this.scrubFilenameAsUuid = this.scrubFilenameAsUuid.bind(this);
    this.learnQuestionContainer = this.learnQuestionContainer.bind(this);
    this.handleAttachmentDestroy = this.handleAttachmentDestroy.bind(this);
  }

  componentDidMount() {
    const node = findDOMNode(this);
    autosize(node.querySelectorAll('textarea'));
    if (this.props.question) {
      let infoHtml = '';
      if (this.props.question.attributes.infoMd) {
        infoHtml = LatexResistentMarkdownConverter.convert(this.props.question.attributes.infoMd);
      }
      this.setState({
        infoHtml,
      });
    }
  }

  handleChange(e) {
    e.preventDefault();
    const { target } = e;
    if (target.classList.contains('question_info_md')) {
      const infoHtml = LatexResistentMarkdownConverter.convert(target.value);
      this.setState({
        disabled: false,
        errors: {},
        infoMd: target.value,
        infoHtml,
      });
    } else {
      const { name } = target.dataset;
      const { value } = target;
      const data = {
        errors: {},
        disabled: false,
      };
      data[name] = value;
      this.setState(data);
    }
  }

  handleCancel(e) {
    e.preventDefault();
    this.props.actions.editQuestion(null);
  }

  handleAttachmentDestroy(e) {
    e.preventDefault();
    swal.fire({
      title: 'Are you sure you want to delete this attachment?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d30a09',
      cancelButtonColor: '#28c664',
      confirmButtonText: 'Yes, delete it!',
      focusCancel: true,
    }).then((result) => {
      if (result.value) {
        const { attachment } = this.props;
        const payload = {
          url: attachment.links.self,
          method: 'DELETE',
          successCallbacks: [Actions.REMOVE_QUESTION_ATTACHMENT],
        };
        this.props.actions.fetch(payload);
      }
    });
  }

  onReceivedUploadFilename(result) {
    const filename = result.signedUrl.match(/([^/?#]+)(?:[?#].*)?$/)[1];
    this.setState({ uploadFilename: filename });
  }

  onUploadProgress(percent, message) {
    this.setState({ uploadingText: `${message} ${percent}%` });
  }

  onUploadError(message) {
    this.setState({
      errors: { upload: [`unsuccessful: ${message}`] },
      uploadingText: null,
    });
  }

  onUploadFinish(result) {
    this.setState({
      disabled: false,
      uploadingText: null,
      errors: {},
      uploadUrl: result.signedUrl.split('?')[0],
    });
  }

  onClearUploader() {
    this.uploader.clear();
    this.setState({
      disabled: false,
      uploadingText: null,
      errors: {},
    });
  }

  setUploader(component) {
    this.uploader = component;
  }

  scrubFilenameAsUuid() {
    return this.state.uploadFilename;
  }

  submitForm(e) {
    e.preventDefault();
    const _this = this;
    this.setState({ disabled: true });

    $.ajax({
      url: this.props.url,
      method: this.props.question ? 'PUT' : 'POST',
      data: {
        question: {
          text: this.state.text,
          info_md: this.state.infoMd,
          info_html: this.state.infoHtml,
        },
        upload_url: this.state.uploadUrl,
      },
    }).done((response, message, xhr) => {
      if (_this.props.question) {
        _this.props.actions.updateQuestion(response);
        _this.props.actions.cancelEditQuestion(response);
        _this.props.actions.setIncludedItems(response);
      } else {
        _this.props.actions.setIncludedQuestionAnswers(response);
        _this.props.actions.setIncludedUsers(response);
        _this.props.actions.setIncludedItems(response);
        _this.props.actions.addQuestion(response);
        $('.modal').modal('hide').on('hidden.bs.modal', () => {
          _this.setState({
            errors: {},
            disabled: false,
            text: '',
            infoMd: '',
            infoHtml: '',
            uploadUrl: null,
            uploadingText: null,
          });
        });
        _this.uploader.clear();
      }
      Flash.handleFlashMessagesHeader(window.flashDiv, xhr);
    }).fail((jqXHR, textStatus, errorThrown) => {
      if (errorThrown === 'Unauthorized') {
        document.location.reload(true);
      } else if (jqXHR.responseJSON && jqXHR.responseJSON.errors) {
        const { errors } = jqXHR.responseJSON;
        _this.setState({
          errors,
          disabled: false,
        });
      }
      Flash.handleFlashMessagesHeader(window.flashDiv, jqXHR);
    });
  }

  preprocessUpload(file, preProcessDone) {
    // check file type
    const typeMismatch = file.type.match(/image\/(jpg|jpeg|gif|png)/i) === null;
    const fileTooLarge = file.size > 1000000;
    const errors = [];

    if (typeMismatch) {
      errors.push('must be JPEG, GIF or PNG');
    }

    if (fileTooLarge) {
      errors.push('must not be larger than 1MB');
    }

    if (errors.length > 0) {
      this.setState({ errors: { upload: errors } });
      return null;
    }

    this.setState({ uploadingText: 'Upload started', disabled: true });
    return preProcessDone(file);
  }

  learnQuestionContainer() {
    return document.querySelector('#learn-unit-question-form-component');
  }

  signingHeaders() {
    const csrfContainer = document.querySelector('meta[name=csrf-token]');
    let token = '';

    if (csrfContainer) {
      token = csrfContainer.content;
    }

    return { 'X-CSRF-Token': token };
  }

  signingQueryParams() {
    let pathRoot;

    if (this.props.question) {
      pathRoot = this.props.question.attributes.uploadRoot;
    } else if (this.learnQuestionContainer()) {
      pathRoot = this.learnQuestionContainer().dataset.uploadRoot;
    } else {
      console.log('ERROR Cannot find uploadRoot for question attachment. Upload will fail.');
    }

    return {
      path_root: pathRoot,
    };
  }

  render() {
    const { errors } = this.state;

    const textInputName = 'question[text]';
    const textInputId = `question${this.props.questionId || ''}-text`;
    let textInputClass = 'question_text form-control';
    const errorsText = [];
    if (errors.text) {
      textInputClass += ' is-invalid';
      $.each(errors.text, (i, error) => {
        errorsText.push(<div key={i} className="invalid-feedback">Text {error}</div>);
      });
    }

    const errorsUpload = [];
    let uploadInputClass = 'question_upload form-control-file';
    if (errors.upload) {
      uploadInputClass += ' is-invalid';
      $.each(errors.upload, (i, error) => {
        errorsUpload.push(<div key={i} className="invalid-feedback">Upload {error}</div>);
      });
    }

    const infoMdInputName = 'question[info_md]';
    const infoMdInputId = `question${this.props.questionId || ''}-info`;
    const infoMdInputClass = 'question_info_md form-control';
    const infoHtmlInputName = 'question[info_html]';

    const infoField = (
      <MarkdownPreviewTextareaControlled
        label="Additional info"
        labelClassName="col-form-label"
        id={infoMdInputId}
        className={infoMdInputClass}
        mdName={infoMdInputName}
        htmlName={infoHtmlInputName}
        valueMd={this.state.infoMd}
        valueHtml={this.state.infoHtml}
        errors={errors.info_md}
        required="false"
        onChange={this.handleChange}
        isLearner={this.props.isLearner}
      />
    );
    let submitText = 'Post question';
    let submitingText = 'Posting question';
    let cancelButton = <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>;
    let attachmentPreview;

    if (this.props.attachment) {
      const { attachment } = this.props;
      let destroyAttachmentLink;
      if (attachment.attributes.destroyable) {
        destroyAttachmentLink = <a href="#" className="link" onClick={this.handleAttachmentDestroy}>Remove</a>;
      }
      attachmentPreview = (
        <div className="mb-2 col">
          <a href={attachment.attributes.url} target="_blank" rel="noopener noreferrer" className="mr-2" aria-label="Question attachment thumbnail">
            <img src={attachment.attributes.url} className="question-attachment-thumbnail" />
          </a>
          {destroyAttachmentLink}
        </div>
      );
    }

    if (this.props.question) {
      submitText = 'Update question';
      submitingText = 'Updating question';
      cancelButton = <button type="button" className="btn btn-secondary" onClick={this.handleCancel}>Cancel</button>;
    }

    let uploadingText = '';
    let clearUploadButton = '';

    if (this.state.errors.upload && this.state.errors.upload.length > 0) {
      clearUploadButton = <button type="button" className="btn btn-secondary" onClick={this.onClearUploader}>Reset upload</button>;
    }

    if (this.state.uploadingText) {
      uploadingText = <span>{this.state.uploadingText}</span>;
    }

    return (
      <form id="learn-question-form" onSubmit={this.submitForm}>
        <div className="form-group row">
          <label className="col-sm-3 col-form-label" htmlFor={textInputId}>
            Question
            <span className="red_star">
              *
            </span>
          </label>
          <div className="col-sm-9">
            <input autoFocus="autofocus" id={textInputId} className={textInputClass} name={textInputName} type="text" data-name="text" value={this.state.text} onChange={this.handleChange} />
            {errorsText}
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-3 col-form-label" htmlFor="question_info">Additional info</label>
          <div className="col-sm-9">
            {infoField}
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-3 col-form-label" htmlFor="question_image">Upload screenshot</label>
          {attachmentPreview}
          <div className="col">
            <ReactS3Uploader
              signingUrl="/api/uploads/presigned_urls"
              signingUrlMethod="POST"
              signingUrlQueryParams={this.signingQueryParams()}
              signingUrlHeaders={this.signingHeaders()}
              signingUrlWithCredentials={true}
              uploadRequestHeaders={{ }}
              contentDisposition="auto"
              onProgress={this.onUploadProgress}
              onError={this.onUploadError}
              onFinish={this.onUploadFinish}
              onSignedUrl={this.onReceivedUploadFilename}
              scrubFilename={this.scrubFilenameAsUuid}
              preprocess={this.preprocessUpload}
              className={uploadInputClass}
              ref={(component) => this.setUploader(component)}
            />
            {clearUploadButton}
            {uploadingText}
            {errorsUpload}
          </div>
        </div>
        <div className="modal-footer">
          <input type="submit" name="commit" value={submitText} className="btn btn-primary" data-disable-with={submitingText} disabled={this.state.disabled} />
          {cancelButton}
        </div>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    forms: state.forms,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LearnQuestionFormComponent);
