import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import LearnUnitComponent from './components/learn/units/learn-unit-component';
import LearnCommentsContainer from './containers/learn/comments';
import LearnCommentFormComponent from './components/learn/comments/learn-comment-form-component';

import MarkdownPreviewTextarea from './components/markdown-preview-textarea-component';

export function renderLearnUnitComponentProvider(store) {
  const learnUnitElement = document.getElementById('learn-unit-component');
  if (learnUnitElement) {
    const { data, completed } = learnUnitElement.dataset;
    render(
      <Provider store={store}>
        <LearnUnitComponent
          data={data}
          completed={completed}
          store={store}
        />
      </Provider>,
      learnUnitElement,
    );
  }
}

export function renderLearnCommentsContainerProvider(store) {
  const commentsComponent = document.getElementById('learn-comments-component');
  if (commentsComponent) {
    const { url } = commentsComponent.dataset;
    const { currentUserAvatarUrl } = commentsComponent.dataset;
    const isLearner = (commentsComponent.dataset.isLearner === 'true');
    render(
      <Provider store={store}>
        <LearnCommentsContainer
          url={url}
          currentUserAvatarUrl={currentUserAvatarUrl}
          isLearner={isLearner}
        />
      </Provider>,
      commentsComponent,
    );
  }
}

export function renderLearnCommentFormComponentProvider(store) {
  const commentFormComponent = document.getElementById('learn-project-comment-form-component');
  if (commentFormComponent) {
    const { url } = commentFormComponent.dataset;
    const { calendlyUrl } = commentFormComponent.dataset;
    const isLearner = (commentFormComponent.dataset.isLearner === 'true');
    render(
      <Provider store={store}>
        <LearnCommentFormComponent
          url={url}
          calendlyUrl={calendlyUrl}
          isLearner={isLearner}
        />
      </Provider>,
      commentFormComponent,
    );
  }
}

export function renderMarkdownPreviewTextarea() {
  const mdInputs = document.getElementsByClassName('form-md-input');
  [].forEach.call(mdInputs, (mdInput) => {
    const { label } = mdInput.dataset;
    const { labelClassName } = mdInput.dataset;
    const { id } = mdInput.dataset;
    const { className } = mdInput.dataset;
    const { mdName } = mdInput.dataset;
    const { htmlName } = mdInput.dataset;
    const { value } = mdInput.dataset;
    const { required } = mdInput.dataset;
    render(
      <MarkdownPreviewTextarea
        label={label}
        labelClassName={labelClassName}
        id={id}
        className={className}
        mdName={mdName}
        htmlName={htmlName}
        value={value}
        required={required}
      />,
      mdInput,
    );
  });
}
