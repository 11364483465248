import React from 'react';
import autosize from 'autosize/dist/autosize.js';
import MarkdownPreviewTextarea from '../markdown-preview-textarea-component';

class EditAnswerComponent extends React.Component {
  componentDidMount() {
    autosize(document.querySelectorAll('textarea'));
  }

  render() {
    const { answer } = this.props;
    const { id } = answer;
    const { objectType } = this.props;

    const idInputName = `${objectType}[answers_attributes][${id}][id]`;

    const textMdInputName = `${objectType}[answers_attributes][${id}][text_md]`;
    const textMdInputId = `${objectType}_answers_attributes_${id}_text_md`;
    const textMdInputClass = `${objectType}_answers_attributes_text_md form-control`;
    const textHtmlInputName = `${objectType}[answers_attributes][${id}][text_html]`;

    const textField = (
      <MarkdownPreviewTextarea
        label="Text"
        labelClassName="col-form-label"
        id={textMdInputId}
        className={textMdInputClass}
        mdName={textMdInputName}
        htmlName={textHtmlInputName}
        value={answer.textMd}
        required
      />
    );

    const correctInputName = `${objectType}[answers_attributes][${id}][correct]`;
    const correctInputId = `${objectType}_answers_attributes_${id}_correct`;
    const correctInputClass = `${objectType}_answers_attributes_correct form-check-input`;
    const correctInputChecked = answer.correct;

    const hintMdInputName = `${objectType}[answers_attributes][${id}][hint_md]`;
    const hintMdInputId = `${objectType}_answers_attributes_${id}_hint_md`;
    const hintMdInputClass = `${objectType}_answers_attributes_hint_md form-control`;
    const hintHtmlInputName = `${objectType}[answers_attributes][${id}][hint_html]`;

    const hintField = (
      <MarkdownPreviewTextarea
        label="Hint"
        labelClassName="col-form-label"
        id={hintMdInputId}
        className={hintMdInputClass}
        mdName={hintMdInputName}
        htmlName={hintHtmlInputName}
        value={answer.hintMd}
      />
    );

    const successMessageMdInputName = `${objectType}[answers_attributes][${id}][success_message_md]`;
    const successMessageMdInputId = `${objectType}_answers_attributes_${id}_success_message_md`;
    const successMessageMdInputClass = `${objectType}_answers_attributes_success_message_md form-control`;
    const successMessageHtmlInputName = `${objectType}[answers_attributes][${id}][success_message_html]`;

    const successMessageField = (
      <MarkdownPreviewTextarea
        label="Success Message"
        labelClassName="col-form-label"
        id={successMessageMdInputId}
        className={successMessageMdInputClass}
        mdName={successMessageMdInputName}
        htmlName={successMessageHtmlInputName}
        value={answer.successMessageMd}
      />
    );

    const positionInputName = `${objectType}[answers_attributes][${id}][position]`;
    const destroyInputName = `${objectType}[answers_attributes][${id}][_destroy]`;
    const destroyInputId = `${objectType}_answers_attributes_${id}_destroy`;

    const sortHandle = (
      <span className="sortable-handle pull-right" title="Drag to sort">
        <i className="fas fa-sort" aria-hidden="true" />
      </span>
    );

    return (
      <fieldset className="answer answer-sortable" data-id={id}>
        {sortHandle}
        <input type="hidden" name={idInputName} value={id} />
        <input type="hidden" name={positionInputName} defaultValue={this.props.position} />
        {textField}
        {hintField}
        {successMessageField}
        <div className="form-check">
          <input type="hidden" name={correctInputName} className={correctInputClass} value="0" />
          <input type="checkbox" id={correctInputId} name={correctInputName} className={correctInputClass} defaultChecked={correctInputChecked} value="1" />
          <label htmlFor={correctInputId} className="form-check-label">
            Correct
          </label>
        </div>
        <div className="form-check">
          <input type="hidden" name={destroyInputName} value="0" />
          <input className="form-check-input" type="checkbox" name={destroyInputName} value="1" id={destroyInputId} />
          <label className="form-check-label" htmlFor={destroyInputId}>
            Remove
          </label>
        </div>
      </fieldset>
    );
  }
}

export default EditAnswerComponent;
