/* eslint no-console:0 */

import 'prismjs/components/prism-css.js';
import 'prismjs/components/prism-r.js';
import 'prismjs/components/prism-python.js';
import 'prismjs/components/prism-ruby.js';
import 'prismjs/components/prism-javascript.js';
import 'prismjs/components/prism-java.js';
import 'prismjs/components/prism-scala.js';
import 'prismjs/plugins/line-highlight/prism-line-highlight.js';

import '../../components/learn/notifications/notifications-component.jsx';
import '../../components/flash.jsx';

import '../../components/learn/units/learn-unit-component.jsx';
import '../../components/learn/billing_address/billing_address.js';

import '../../index_base.jsx';
import '../../index_learn.jsx';

import '../../utilities/learn/program_nav';
import '../../utilities/learn/subscriptions';
import '../../utilities/learn/sweetalert2';

import '../../controllers/index_learn';

import '@fortawesome/fontawesome-free/js/all';
import '@github/markdown-toolbar-element';
import '@hotwired/turbo-rails';

require('@rails/ujs').start();

window.App = window.App || {};

$(document).on('turbo:load', () => {
  Prism.highlightAll();
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();
  if (typeof MathJax !== 'undefined') {
    MathJax.Hub.Queue(['Typeset', MathJax.Hub]);
  }

  window.cookieconsent.initialise({
    position: 'bottom-right',
    content: {
      href: 'https://learn.extensionschool.ch/cookies',
      message: 'We use cookies to ensure you get the best experience on our website.',
    },
  });
});
