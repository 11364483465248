import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import autosize from 'autosize/dist/autosize.js';

import _ from 'lodash';
import * as Actions from '../../../actions_learn';
import Flash from '../../../utilities/flash';

class LearnQuizzTextElementComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdateAnswer = this.handleUpdateAnswer.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
  }

  componentDidMount() {
    const { element } = this.props;
    this.setState({
      element,
      answer: element.attributes.subscriptionLastAnswer || '',
      message: null,
      errors: null,
      success: !!element.attributes.subscriptionLastAnswer,
    });
    const completions = { ...this.props.completions, [element.id]: (!!element.attributes.subscriptionLastAnswer) };
    this.props.actions.setCompletionState({ completions });
    autosize(document.querySelectorAll('textarea'));
  }

  componentDidUpdate() {
    MathJax.Hub.Queue(['Typeset', MathJax.Hub]);
    Prism.highlightAll();
  }

  handleChange(event) {
    this.setState({
      answer: event.target.value,
    });
  }

  handleUpdateAnswer() {
    this.setState({
      success: false,
    });
  }

  handleSuccess(truthy) {
    const completions = {
      ...this.props.completions,
      [this.state.element.id]: truthy,
    };

    this.props.actions.setCompletionState({ completions });

    const buttonDisabled = _.includes(Object.values(this.props.completions), false);
    if (!buttonDisabled) {
      Flash.setMessage(window.flashDiv, 'success', 'Next unit enabled');
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    const _this = this;
    const { element, answer } = this.state;
    const url = element.links.textQuizzAnswer;
    $.ajax({
      beforeSend(xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')); },
      url,
      type: 'post',
      data: {
        text_quizz_answer: {
          content: answer,
        },
      },
    }).done(() => {
      _this.handleSuccess(true);
      _this.setState({
        errors: null,
        message: element.attributes.successMessageHtml,
        success: true,
      });
    }).fail((jqXHR, textStatus, errorThrown) => {
      _this.handleSuccess(false);
      _this.setState({
        errors: jqXHR.responseJSON ? jqXHR.responseJSON.errors : { Error: [errorThrown] },
        success: false,
      });
    });
  }

  render() {
    const { element } = this.state;

    const answerInputName = 'text_quizz_answer[content]';
    const answerInputId = 'text_quizz_answer_content';
    const answerFormGroupClass = 'form-group';
    const answerInputClass = 'text_quizz_answer_content form-control';

    const errors = [];
    let message;

    if (this.state.message) {
      message = (
        <div className="card mb-3 text-white bg-success">
          <div className="card-header" dangerouslySetInnerHTML={{ __html: this.state.message }} />
        </div>
      );
    }

    if (this.state.errors) {
      $.each(this.state.errors, (attr, errs) => {
        let attribute = attr;
        if (attr === 'content') { attribute = 'Answer'; }
        $.each(errs, (i, err) => {
          errors.push(
            <div key={i} className="alert alert-danger">
              <div className="help-block">
                {attribute} {err}
              </div>
            </div>,
          );
        });
      });
    }

    let content;
    if (this.state.success) {
      content = (
        <div>
          <div dangerouslySetInnerHTML={{ __html: element ? element.attributes.descriptionHtml : '' }} />
          {message}
          <h2>Your answer</h2>
          <div>
            <p>{this.state.answer}</p>
          </div>
          <div className="form-group">
            <button className="btn btn-info" onClick={this.handleUpdateAnswer}>
              Update answer
            </button>
          </div>
        </div>
      );
    } else {
      content = (
        <form className="quizz" onSubmit={this.handleSubmit}>
          <div dangerouslySetInnerHTML={{ __html: element ? element.attributes.descriptionHtml : '' }} />
          {errors}
          <div className={answerFormGroupClass}>
            <textarea onChange={this.handleChange} id={answerInputId} name={answerInputName} className={answerInputClass} value={this.state.answer} />
          </div>
          <div className="form-group">
            <button type="submit" className="btn btn-info">
              Submit answer
            </button>
          </div>
        </form>
      );
    }

    return (content);
  }
}

function mapStateToProps(state) {
  return {
    completions: state.unit ? state.unit.completions : [],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LearnQuizzTextElementComponent);
